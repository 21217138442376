import React, { useEffect, useMemo, useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import moment from "moment/moment";
import { Stack } from "@mui/system";
import Skeleton from "@mui/material/Skeleton";
import { GET } from "../../Functions/apiFunction";
import api from "../../Data/api";
import "../../Styles/buttons.css";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
} from "chart.js";
import { useSelector } from "react-redux";
import Utils from "../../Global/utils";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../assets/a_logo.png";

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "90vw",
    sm: "fit-content",
    md: "fit-content",
    lg: "fit-content",
    xl: "fit-content",
  },
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: 2,
  textAlign: "center",
};

function DeliveryReport() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const appSetting = useSelector((state) => {
    return state.AppSettings[state.AppSettings.length - 1];
  });
  const [hasDeliveryPartner, setHasDeliveryPartner] = useState(false);
  const [reports, setreports] = useState();
  const [pageSize, setpageSize] = useState(20);
  const [open, setOpen] = useState(false);
  const [clearAuto, setclearAuto] = useState(1);
  const [dataSet, setdataSet] = useState([]);
  const [backdropOpen, setbackdropOpen] = useState(false);
  //
  const [drivers, setdrivers] = useState();
  const [selectedDriver, setselectedDriver] = useState();
  const [isDateRange, setisDateRange] = useState(false);
  const [dateRange, setdateRange] = useState([
    {
      endDate: new Date(),
      startDate: addDays(new Date(), -7),
      key: "selection",
    },
  ]);
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleBackDropOpen = () => setbackdropOpen(true);
  const handleBackDropClose = () => setbackdropOpen(false);

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = `Bearer ${admin.token}`;

  useEffect(() => {
    // Get categoriues
    const getCat = async () => {
      const hasDelivery =
        appSetting &&
        appSetting?.find((setting) => setting.title == "HasDeliveryPartner")
          ?.value === "true";
      setHasDeliveryPartner(hasDelivery);
      const url = `${api}/get_report/delivery`;
      const report = await GET(token, url);
      setreports(report.data);

      let array = getDateValueArray(report.data);
      const data_Set = () => {
        let arr = [];
        for (let i = 0; i < array.length; i++) {
          let data = {
            date: array[i].date,
            value: array[i].values.length,
          };
          arr.push(data);
        }
        setdataSet(arr);
      };
      data_Set();
    };
    const getDriver = async () => {
      const url = `${api}/get_user/role/4`;
      const drivers = await GET(token, url);
      setdrivers(drivers.data);
    };
    getCat();
    getDriver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const filter = async (url) => {
    handleBackDropOpen();
    const report = await GET(token, url);
    handleBackDropClose();
    setreports(report.data);

    let array = getDateValueArray(report.data);
    const data_Set = () => {
      let arr = [];
      for (let i = 0; i < array.length; i++) {
        let data = {
          date: array[i].date,
          value: array[i].values.length,
        };
        arr.push(data);
      }
      setdataSet(arr);
    };

    data_Set();
  };

  function getDateValueArray(inputArray) {
    // Create an object to store the unique dates and their values
    const dateValueObj = inputArray?.reduce((acc, curr) => {
      // Get the current date from the input array item
      const currDate = curr.date;

      // If the date is not already in the accumulator object, add it
      if (!acc[currDate]) {
        acc[currDate] = [];
      }

      // Add the current item's value to the date's array in the accumulator object
      acc[currDate].push(1);

      return acc;
    }, {});

    // Convert the accumulator object to an array
    const dateValueArray = Object.entries(dateValueObj).map(
      ([date, values]) => {
        return { date, values };
      }
    );

    return dateValueArray;
  }

  const exportToCSV = () => {
    const dateRange =
      startDate && endDate ? `Date Range: ${startDate} to ${endDate}` : null;

    // Prepare the headers and data
    const headers = [
      "Name",
      "Phone",
      "Customer ID",
      "Title",
      "Order ID",
      "Subscription Type",
      "Order Type",
      "Qty",
      "Pincode",
      "Delivery Notes",
      "Delivered By",
      "Address",
      "Delivered Date",
    ];

    const csvData = reports.map((row) => [
      row.name,
      row.s_phone,
      row.entry_user_id,
      row.subscription_type !== null
        ? row.title
        : JSON.parse(row.product_detail)
            ?.map((product) => `${product.product_title} (Qty ${product.qty})`)
            .join(", "),
      row.order_number,
      Utils.getSubscriptionType(row.subscription_type),
      Utils.getOrderType(row.order_type),
      row.qty,
      row.pincode,
      row.delivery_notes,
      "Admin",
      Utils.formatAddress(row),
      moment.utc(row.date).local().format("DD-MM-YYYY"),
    ]);

    // Create worksheet and workbook
    const workbook = XLSX.utils.book_new();

    // Prepare the data for the worksheet
    const tempData = [
      ...(startDate && endDate ? [[dateRange], []] : []), // Add date range and empty row if available
      headers,
      ...csvData,
    ];

    // Convert tempData to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(tempData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Delivery Report");

    // Set the filename and download
    XLSX.writeFile(
      workbook,
      `Delivery_Report_${new Date().toLocaleDateString()}.csv`
    );
  };

  const exportToPDF = () => {
    const dateRange = `Date Range: ${startDate} to ${endDate}`;
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4",
    });

    // Add the header text
    doc.setFontSize(18);
    const headerText = "Delivery Report";
    const headerX =
      (doc.internal.pageSize.getWidth() - doc.getTextWidth(headerText)) / 2;
    doc.text(headerText, headerX, 28);

    // Load the logo and add it to the document
    Utils.getBase64FromImage(logo, (base64Logo) => {
      const logoWidth = 20;
      const logoHeight = 30;
      const pageWidth = doc.internal.pageSize.getWidth();
      doc.addImage(
        base64Logo,
        "PNG",
        pageWidth - logoWidth - 20,
        10,
        logoWidth,
        logoHeight
      );

      // Set smaller font size for the date range text below header
      doc.setFontSize(12);
      if (startDate && endDate) {
        doc.text(dateRange, 20, 40);
      }

      // Define table headers with column names and configure column width
      const tableColumn = [
        { header: "Name", dataKey: "name" },
        { header: "Phone", dataKey: "phone" },
        { header: "Customer ID", dataKey: "user_Id" },
        { header: "Title", dataKey: "title" },
        { header: "Order ID", dataKey: "order_number" },
        { header: "Subscription Type", dataKey: "subscription_type" },
        { header: "Order Type", dataKey: "order_type" },
        { header: "Qty", dataKey: "qty" },
        { header: "Pincode", dataKey: "pincode" },
        { header: "Delivery Notes", dataKey: "delivery_notes" },
        { header: "Delivered By", dataKey: "delivered_by" },
        { header: "Address", dataKey: "address" },
        { header: "Delivered Date", dataKey: "date" },
      ];

      // Map table rows and format data as needed
      const tableRows = reports.map((row) => ({
        name: row.name,
        phone: row.s_phone,
        user_Id: row.entry_user_id,
        title:
          row.subscription_type !== null
            ? row.title
            : JSON.parse(row.product_detail)
                ?.map(
                  (product) => `${product.product_title} (Qty ${product.qty})`
                )
                .join(", "),
        order_number: row.order_number,
        subscription_type: Utils.getSubscriptionType(row.subscription_type),
        order_type: Utils.getOrderType(row.order_type),
        qty: row.qty,
        pincode: row.pincode,
        delivery_notes: row.delivery_notes,
        delivered_by: "Admin",
        address: Utils.formatAddress(row),
        date: moment.utc(row.date).local().format("DD-MM-YYYY"),
      }));

      const tableStartY = 10 + logoHeight + 6;

      // Your table configuration remains unchanged
      doc.autoTable({
        head: [tableColumn.map((col) => col.header)],
        body: tableRows.map((row) => Object.values(row)),
        startY: tableStartY,
        margin: { left: 20 },
        headStyles: {
          fillColor: [255, 165, 0], // Orange background
          textColor: [255, 255, 255], // White text
          fontSize: 10, // Reduced font size for header
          lineWidth: 0.2, // Set border thickness for header
          halign: "center", // Center-align the table headers
        },
        bodyStyles: {
          lineWidth: 0.2, // Set border thickness for body cells
          lineColor: [0, 0, 0], // Black border color
        },
        styles: {
          fontSize: 10, // Adjust font size for table content
          cellPadding: 3, // Add padding to cells for better appearance
          lineWidth: 0.2, // General border thickness
          lineColor: [0, 0, 0], // General border color
        },
        columnStyles: {
          0: { cellWidth: 18 },
          3: { cellWidth: 40 },
          9: { cellWidth: 25 },
          10: { cellWidth: 25 },
          11: { cellWidth: 25 },
        },
        showHead: "firstPage",
      });

      // After the table is completely generated, add the page numbers
      const totalPages = doc.internal.getNumberOfPages(); // Get total pages
      doc.setFontSize(9);
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i); // Set the page context to the current page
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const pageText = `Page ${i} of ${totalPages}`; // Format "Page X/Y"
        const marginRight = 15;
        const marginBottom = i === 1 ? 7 : 10;

        // Add page number at the bottom-right of the page
        doc.text(
          pageText,
          pageWidth - marginRight - doc.getTextWidth(pageText),
          pageHeight - marginBottom
        );
      }

      // Save the PDF
      doc.save(`Delivery_Report_${new Date().toLocaleDateString()}.pdf`);
    });
  };

  const column = useMemo(
    () => [
      { field: "name", headerName: "Name", width: 160 },
      { field: "s_phone", headerName: "Phone", width: 120 },
      { field: "entry_user_id", headerName: "Customer ID", width: 120 },
      {
        field: "title",
        headerName: "Title",
        width: 180,
        renderCell: (params) => {
          const title =
            params.row.subscription_type !== null
              ? `${params.row.title} (Qty ${params.row.qty})`
              : JSON.parse(params.row.product_detail)
                  ?.map(
                    (product) => `${product.product_title} (Qty ${product.qty})`
                  )
                  .join(", ");

          return (
            <Tooltip title={title} arrow>
              <Typography
                variant="body2"
                noWrap
                style={{
                  maxWidth: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {title}
              </Typography>
            </Tooltip>
          );
        },
      },
      { field: "order_number", headerName: "Order ID", width: 120 },
      {
        field: "subscription_type",
        headerName: "Subscription Type",
        width: 130,
        renderCell: (params) => {
          let subscriptionText = Utils.getSubscriptionType(
            params.row.subscription_type
          );
          return <p>{subscriptionText}</p>;
        },
      },
      {
        field: "order_type",
        headerName: "Order Type",
        width: 120,
        renderCell: (params) => {
          let orderText = Utils.getOrderType(params.row.order_type);
          return <p>{orderText}</p>;
        },
      },

      {
        field: "qty",
        headerName: "Qty",
        width: 80,
      },
      {
        field: "pincode",
        headerName: "Pincode",
        width: 140,
      },
      {
        field: "delivery_notes",
        headerName: "Delivery Notes",
        width: 220,
      },
      {
        field: "delivered_by",
        headerName: "Delivered By",
        width: 100,
        renderCell: (params) => "Admin",
      },
      {
        field: "address",
        headerName: "Address",
        width: 220,
        renderCell: (params) => {
          // Call the utility function to format the address
          const address = Utils.formatAddress(params.row);

          return (
            <Tooltip title={address} arrow>
              <Typography
                variant="body2"
                noWrap
                style={{
                  maxWidth: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {address}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "date",
        headerName: "Delivered Date",
        width: 120,
        renderCell: (params) =>
          moment.utc(params.row.date).local().format("DD-MM-YYYY"),
      },
    ],
    []
  );

  // custom toolbar
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <div>
          <Box sx={{ display: "flex", gap: "15px" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={exportToCSV}
              disabled={reports.length === 0}
            >
              Export to CSV
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={exportToPDF}
              disabled={reports.length === 0}
            >
              Export to PDF
            </Button>
          </Box>
        </div>
      </GridToolbarContainer>
    );
  }

  return (
    <div>
      <Box sx={{ height: 600, width: "100%" }}>
        <Typography
          variant="h2"
          component={"h2"}
          fontWeight={500}
          sx={{ textAlign: "center", pb: "8px" }}
        >
          Delivery Report
        </Typography>
        <Divider />

        <Box mt={4} display={"flex"} alignItems={"center"} gap={"30px"}>
          {hasDeliveryPartner && (
            <Autocomplete
              key={clearAuto}
              disablePortal
              sx={{ width: "40%" }}
              id="combo-box-demo"
              color="secondary"
              clearIcon
              options={drivers ? drivers : []}
              getOptionLabel={(option) =>
                `${option?.name} ( ${option?.phone} , ${option?.email})`
              }
              onChange={(e, data) => {
                setselectedDriver(data.user_id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  Autocomplete={false}
                  label="Select Driver"
                  size="small"
                  fullWidth
                  color="secondary"
                />
              )}
            />
          )}

          <TextField
            InputLabelProps={{ shrink: true }}
            id="outlined-basic"
            label="Select Date Range"
            variant="outlined"
            Autocomplete={false}
            size="small"
            color="secondary"
            onKeyDown={() => {
              return false;
            }}
            onClick={handleOpen}
            value={startDate && `${startDate} - ${endDate}`}
          />

          <Button
            variant="contained"
            sx={{
              fontWeight: "700",
              color: "fff",
              width: "150px",
            }}
            color="secondary"
            onClick={() => {
              if (isDateRange === true) {
                let url = `${api}/get_report/delivery/${startDate}/${endDate}`;
                filter(url);
              } else {
                let url = `${api}/get_report/delivery`;
                filter(url);
              }
            }}
          >
            Submit
          </Button>

          <Button
            variant="contained"
            sx={{ fontWeight: "700", color: "fff" }}
            color="primary"
            onClick={() => {
              setisDateRange(false);
              setselectedDriver();
              setclearAuto(clearAuto === 1 ? 0 : 1);
              setstartDate("");
              setendDate("");
              let url = `${api}/get_report/delivery`;
              filter(url);
              setdateRange([
                {
                  endDate: new Date(),
                  startDate: addDays(new Date(), -7),
                  key: "selection",
                },
              ]);
            }}
          >
            Reset
          </Button>
        </Box>
        {dataSet && (
          <Box mt={5} mb={10} border={"1px solid #3D4B65"} borderRadius={"8px"}>
            <Typography
              variant="h2"
              component={"h2"}
              fontWeight={500}
              sx={{ textAlign: "center", pb: "8px", pt: "8px" }}
            >
              Statistics - Delivery Data
            </Typography>{" "}
            <Divider />
            <Box
              height={"350px"}
              display={"flex"}
              p={"20px"}
              justifyContent={"space-around"}
            >
              <Chart
                type="line"
                lineThickness="1"
                data={{
                  labels: dataSet.map((coin) => coin.date),
                  datasets: [
                    {
                      data: dataSet.map((coin) => coin.value),
                      label: `Total Delivery`,
                      borderWidth: 2,
                      fill: true,
                      borderColor: "#28a745",
                      tension: 0.3,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  elements: {
                    point: {
                      radius: 4,
                      borderWidth: 1,
                    },
                  },
                  datalabels: {
                    display: false,
                  },

                  plugins: {
                    datalabels: {
                      display: false,
                    },
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      mode: "index",
                      intersect: false,
                    },
                  },
                  scales: {
                    y: {
                      ticks: {
                        beginAtZero: true,
                        display: false,
                      },
                      title: {
                        display: true,
                        text: "Delivery →",
                        font: {
                          size: "18px",
                          color: "#000",
                        },
                      },
                      display: true, // Hide Y axis labels
                      grid: {
                        color: "#28a7469f",
                        display: false,
                      },
                    },
                    x: {
                      ticks: {
                        beginAtZero: true,
                        display: false,
                      },
                      grid: {
                        color: "#28a7469f",
                        display: false,
                      },
                      title: {
                        display: true,
                        text: "Date →",
                        font: {
                          size: "18px",
                          color: "#000",
                        },
                      },
                      display: true, // Hide X axis labels
                    },
                  },
                }}
              />
            </Box>
          </Box>
        )}

        {reports ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              paddingBottom: "30px",
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-row": {
                fontSize: "14px",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.navbarBG[400],
                borderBottom: "none",
                color: "#f5f5f5",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
                borderBottom: "#000",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.navbarBG[400],
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-root": {
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-selectIcon": {
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-actions botton": {
                color: "#f5f5f5 !important",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            {" "}
            <DataGrid
              sx={{ fontSize: "13px" }}
              columns={column}
              rows={reports}
              components={{ Toolbar: CustomToolbar }}
              localeText={{
                noRowsLabel: "No records found",
              }}
              rowsPerPageOptions={[10, 20, 25, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setpageSize(newPageSize)}
              getRowId={(row) => row.order_id}
            />
          </Box>
        ) : (
          <Stack spacing={1}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            {/* For other variants, adjust the size with `width` and `height` */}

            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
          </Stack>
        )}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <DateRangePicker
            onChange={(item) => {
              setisDateRange(true);
              setstartDate(
                moment(item.selection.startDate).format("YYYY-MM-DD")
              );
              setendDate(moment(item.selection.endDate).format("YYYY-MM-DD"));
              setdateRange([item.selection]);
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={dateRange}
            direction="vertical"
            scroll={{ enabled: true }}
          />
          <Box mt={5}>
            {" "}
            <Button
              fullWidth
              variant="contained"
              sx={{ height: "30px", fontWeight: "700", color: "fff" }}
              color="primary"
              onClick={() => {
                if (!startDate) {
                  setisDateRange(true);
                  setstartDate(
                    moment(dateRange[0].startDate).format("YYYY-MM-DD")
                  );
                  setendDate(moment(dateRange[0].endDate).format("YYYY-MM-DD"));
                }
                handleClose();
              }}
            >
              Set
            </Button>
          </Box>
        </Box>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={backdropOpen}
        onClick={handleBackDropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default DeliveryReport;
