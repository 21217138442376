import { useCallback, useEffect, useMemo, useState } from "react";
import { GET } from "../Functions/apiFunction";
import api from "../Data/api";
import {
  Alert,
  Box,
  Divider,
  Select,
  Skeleton,
  Snackbar,
  Stack,
  Typography,
  MenuItem,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { tokens } from "../theme";
import { useTheme } from "@emotion/react";
import CartItemsModal from "../Components/CartItemsModal";

function CartOrders() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [cartOrdersData, setCartOrders] = useState();
  const [pageSize, setpageSize] = useState(20);
  const [selectedCartItems, setSelectedCartItems] = useState([]);
  const [snakbarOpen, setsnakbarOpen] = useState(false);
  const [alertType, setalertType] = useState("");
  const [alertMsg, setalertMsg] = useState("info");
  const handleSnakBarOpen = () => setsnakbarOpen(true);
  const handleSnakBarClose = () => setsnakbarOpen(false);

  const [openProductModal, setIsOpenProductModal] = useState(false);

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = `Bearer ${admin.token}`;

  const getCartOrders = useCallback(async () => {
    const url = `${api}/get_cart_orders`;
    const result = await GET(token, url);
    if (result.response === 200) {
      setCartOrders(result.data);
    } else {
      handleSnakBarOpen();
      setalertType("error");
      setalertMsg("Something went wrong");
    }
  }, [token]);

  useEffect(() => {
    getCartOrders();
  }, [getCartOrders, token]);

  const handleModalOpen = useCallback(async () => {
    // await getCartOrders();
    setIsOpenProductModal(true);
  }, [getCartOrders]);

  function handleModalClose() {
    setIsOpenProductModal(false);
  }

  const column = useMemo(
    () => [
      {
        field: "user_id",
        headerName: "User Id",
        width: 180,
        renderCell: (params) => params.value || "--",
      },
      {
        field: "name",
        headerName: "Name",
        width: 180,
        renderCell: (params) => params.value || "--",
      },
      {
        field: "phone",
        headerName: "Number",
        width: 180,
        renderCell: (params) => params.value || "--",
      },
      {
        field: "email",
        headerName: "Email",
        width: 180,
        renderCell: (params) => params.value || "--",
      },
      {
        field: "wallet_amount",
        headerName: "Wallet",
        width: 180,
        renderCell: (params) => params.value || "0.00",
      },
      {
        field: "Action",
        headerName: "Action",
        width: 100,
        renderCell: (params) => (
          <button
            class="updateBtn"
            onClick={() => {
              setSelectedCartItems(params?.row?.cart_items || []);
              handleModalOpen();
            }}
          >
            <i class="fa-regular fa-eye"></i>
          </button>
        ),
      },
    ],
    [handleModalOpen]
  );

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <div>
          {" "}
          <GridToolbarExport color="secondary" sx={{ fontSize: "14px" }} />
          <Select
            sx={{
              width: "100px",
              height: "30px",
              marginLeft: "20px",
            }}
            color="primary"
            size="small"
            labelId="demo-select-small"
            id="demo-select-small"
            value={pageSize}
            label="Page Size"
            onChange={(e) => {
              setpageSize(e.target.value);
            }}
            className="TopPageBar"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </div>
      </GridToolbarContainer>
    );
  }

  return (
    <div>
      <Snackbar
        open={snakbarOpen}
        autoHideDuration={3000}
        onClose={handleSnakBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnakBarClose}
          severity={alertType || "info"}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
      <Box sx={{ height: 600, width: "100%" }}>
        <div>
          {" "}
          <Typography
            variant="h2"
            component={"h2"}
            fontWeight={500}
            sx={{ textAlign: "center", pb: "8px" }}
          >
            Cart Orders
          </Typography>
          <Divider />
        </div>

        {cartOrdersData ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              paddingBottom: "30px",
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-row": {
                fontSize: "14px",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.navbarBG[400],
                borderBottom: "none",
                color: "#f5f5f5",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
                borderBottom: "#000",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.navbarBG[400],
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-root": {
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-selectIcon": {
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-actions botton": {
                color: "#f5f5f5 !important",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            {" "}
            <DataGrid
              sx={{ fontSize: "13px" }}
              columns={column}
              rows={cartOrdersData}
              components={{ Toolbar: CustomToolbar }}
              rowsPerPageOptions={[10, 20, 25, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setpageSize(newPageSize)}
              getRowId={(row) => row.user_id}
              localeText={{
                noRowsLabel: "No records found",
              }}
            />
          </Box>
        ) : (
          <Stack spacing={1}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            {/* For other variants, adjust the size with `width` and `height` */}

            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
          </Stack>
        )}
      </Box>
      <CartItemsModal
        cartItems={selectedCartItems}
        open={openProductModal}
        onClose={handleModalClose}
      />
    </div>
  );
}

export default CartOrders;
