import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import {
  TextField,
  Typography,
  useTheme,
  Modal,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment/moment";
import {
  DataGrid,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { Stack } from "@mui/system";
import Skeleton from "@mui/material/Skeleton";
import { GET } from "../../Functions/apiFunction";
import api from "../../Data/api";
import { tokens } from "../../theme";
import { DateRangePicker } from "react-date-range";
import "jspdf-autotable";
import { addDays } from "date-fns";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import logo from "../../assets/a_logo.png";
import Utils from "../../Global/utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "90vw",
    sm: "fit-content",
    md: "fit-content",
    lg: "fit-content",
    xl: "fit-content",
  },
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: 2,
  textAlign: "center",
};

function CustomersReport() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [users, setusers] = useState();
  const [MainUsers, setMainUsers] = useState();
  const [pageSize, setpageSize] = useState(20);

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = `Bearer ${admin.token}`;
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const [backdropOpen, setbackdropOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleBackDropOpen = () => setbackdropOpen(true);
  const handleBackDropClose = () => setbackdropOpen(false);
  const [isDateRange, setisDateRange] = useState(false);
  const [dataSet, setdataSet] = useState([]);
  const [dateRange, setdateRange] = useState([
    {
      endDate: new Date(),
      startDate: addDays(new Date(), -7),
      key: "selection",
    },
  ]);

  useEffect(() => {
    // Get users
    const getCat = async () => {
      const url = `${api}/get_user_report`;
      const users = await GET(token, url);
      setusers(users.data);
      setMainUsers(users.data);
    };
    getCat();
  }, [token]);

  const column = useMemo(() => [
    { field: "id", headerName: "Customer ID", width: 180 },
    { field: "name", headerName: "Name", width: 180 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "phone", headerName: "Phone", width: 150 },
    {
      field: "wallet_amount",
      headerName: "Wallet Amount",
      width: 150,
      renderCell: (params) => (
        <p
          style={{
            color:
              params.row.wallet_amount === null ||
              params.row.wallet_amount < 250
                ? "red"
                : "#54B435",
            fontWeight:
              params.row.wallet_amount === null ||
              params.row.wallet_amount < 250
                ? "700"
                : "700",
          }}
        >
          {params.row.wallet_amount === null ? "0.00" : params.row?.wallet_amount?.toFixed(2)}
        </p>
      ),
    },
    {
      field: "created_at",
      headerName: "Customer Register Date",
      width: 220,
      renderCell: (params) =>
        moment.utc(params.row.created_at).local().format("DD-MM-YYYY"),
    },
    // {
    //   field: "role_title",
    //   headerName: "Role",
    //   width: 150,
    //   renderCell: (params) => (
    //     <>
    //       {params.row.role_title === null ? "USER" : params.row.role_title}
    //     </>
    //   ),
    //   type: "string",
    // },
  ]);

  const exportToCSV = () => {
    const dateRange =
      startDate && endDate ? `Date Range: ${startDate} to ${endDate}` : null;

    // Prepare the headers and data
    const headers = [
      "Customer ID",
      "Name",
      "Email",
      "Phone",
      "Wallet Amount",
      "Customer Register Date",
    ];

    const csvData = users.map((row) => [
      row.id,
      row.name,
      row.email,
      row.phone,
      row.wallet_amount ?? 0,
      moment.utc(row.created_at).local().format("DD-MM-YYYY"),
    ]);

    // Create worksheet and workbook
    const workbook = XLSX.utils.book_new();

    // Prepare the data for the worksheet
    const tempData = [
      ...(startDate && endDate ? [[dateRange], []] : []), // Add date range and empty row if available
      headers,
      ...csvData,
    ];

    // Convert tempData to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(tempData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Customers Report");

    // Set the filename and download
    XLSX.writeFile(
      workbook,
      `Customers_Report_${new Date().toLocaleDateString()}.csv`
    );
  };

  // PDF Export Function
  const exportToPDF = () => {
    const dateRange = `Date Range: ${startDate} to ${endDate}`;

    const doc = new jsPDF();

    doc.setFontSize(18); // Set font size for header
    const headerText = "Customers Report";
    const headerX =
      (doc.internal.pageSize.getWidth() - doc.getTextWidth(headerText)) / 2; // Center the header
    doc.text(headerText, headerX, 28); // Positioning the header text

    Utils.getBase64FromImage(logo, (base64Logo) => {
      const logoWidth = 20;
      const logoHeight = 30;
      const pageWidth = doc.internal.pageSize.getWidth();
      doc.addImage(
        base64Logo,
        "PNG",
        pageWidth - logoWidth - 20,
        10,
        logoWidth,
        logoHeight
      );

      doc.setFontSize(12); // Reset font size for normal text
      if (startDate && endDate) {
        doc.text(dateRange, 14, 40); // Positioning the date range below the header
      }

      const tableColumn = [
        "Customer ID",
        "Name",
        "Email",
        "Phone",
        "Wallet Amount",
        "Customer Register Date",
      ];
      const tableRows = users.map((row) => [
        row.id,
        row.name,
        row.email,
        row.phone,
        row?.wallet_amount?.toFixed(2) ?? "0.00",
        moment.utc(row.created_at).local().format("DD-MM-YYYY"),
      ]);

      const tableStartY = 10 + logoHeight + 6;
      // Add the table to the PDF
      doc.autoTable(tableColumn, tableRows, {
        startY: tableStartY,
        showHead: "firstPage",
        headStyles: {
          fillColor: [255, 165, 0], // RGB color for orange background
          textColor: [255, 255, 255], // White text for contrast
          fontSize: 10, // Font size for the table header
          lineWidth: 0.2, // Set border thickness for header
          halign: "center", // Center-align the table headers
        },
        bodyStyles: {
          lineWidth: 0.2, // Set border thickness for body cells
          lineColor: [0, 0, 0], // Black border color
        },
        styles: {
          fontSize: 10, // Adjust font size for table content
          cellPadding: 3, // Add padding to cells for better appearance
          lineWidth: 0.2, // General border thickness
          lineColor: [0, 0, 0], // General border color
        },
      }); 
      
      // After the table is completely generated, add the page numbers
      const totalPages = doc.internal.getNumberOfPages(); // Get total pages
      doc.setFontSize(9);
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i); // Set the page context to the current page
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const pageText = `Page ${i} of ${totalPages}`; // Format "Page X/Y"
        const marginRight = 15; 
        const marginBottom = i === 1 ? 7 : 10;

        // Add page number at the bottom-right of the page
        doc.text(
          pageText,
          pageWidth - marginRight - doc.getTextWidth(pageText),
          pageHeight - marginBottom
        );
      }

      doc.save(`Customers_Report_${new Date().toLocaleDateString()}.pdf`);
    });
    // Add the date range below the header
  };

  const filter = async (url) => {
    handleBackDropOpen();
    const report = await GET(token, url);
    handleBackDropClose();
    setusers(report.data);
    setMainUsers(report.data);

    let array = getDateValueArray(report.data);
    const data_Set = () => {
      let arr = [];
      for (let i = 0; i < array.length; i++) {
        let data = {
          date: array[i].date,
          value: array[i].values.length,
        };
        arr.push(data);
      }
      setdataSet(arr);
    };

    data_Set();
  };

  function getDateValueArray(inputArray) {
    // Create an object to store the unique dates and their values
    const dateValueObj = inputArray.reduce((acc, curr) => {
      // Get the current date from the input array item
      const currDate = curr.date;

      // If the date is not already in the accumulator object, add it
      if (!acc[currDate]) {
        acc[currDate] = [];
      }

      // Add the current item's value to the date's array in the accumulator object
      acc[currDate].push(1);

      return acc;
    }, {});

    // Convert the accumulator object to an array
    const dateValueArray = Object.entries(dateValueObj).map(
      ([date, values]) => {
        return { date, values };
      }
    );

    return dateValueArray;
  }

  // custom toolbar
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <TextField
              InputLabelProps={{ shrink: true }}
              id="outlined-basic"
              label="Select Date Range"
              variant="outlined"
              Autocomplete={false}
              size="small"
              color="secondary"
              onKeyDown={() => {
                return false;
              }}
              onClick={handleOpen}
              value={startDate && `${startDate} - ${endDate}`}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                if (isDateRange === true) {
                  let url = `${api}/get_user_report/${startDate}/${endDate}`;
                  filter(url);
                } else {
                  let url = `${api}/get_user_report`;
                  filter(url);
                }
              }}
              disabled={!startDate && !endDate}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setisDateRange(false);
                setstartDate("");
                setendDate("");
                let url = `${api}/get_user_report`;
                filter(url);
                setdateRange([
                  {
                    endDate: new Date(),
                    startDate: addDays(new Date(), -7),
                    key: "selection",
                  },
                ]);
              }}
              disabled={!startDate && !endDate}
            >
              Reset
            </Button>
          </Box>

          <Box sx={{ display: "flex", gap: "15px" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={exportToCSV}
              disabled={users.length === 0}
            >
              Export to CSV
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={exportToPDF}
              disabled={users.length === 0}
            >
              Export to PDF
            </Button>
          </Box>
        </div>
      </GridToolbarContainer>
    );
  }

  return (
    <div>
      <Box sx={{ height: 600, width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // flexDirection: "row-reverse",
          }}
        >
          {" "}
          <Typography variant="h3" component={"h3"} sx={{ textAlign: "left" }}>
            Customers Report
          </Typography>
          <TextField
            margin="normal"
            size="small"
            sx={{ width: { xs: "80%", sm: "300px", md: "500px" } }}
            id="Search"
            label="Search"
            name="Search"
            color="secondary"
            onChange={(e) => {
              e.preventDefault();
              setTimeout(() => {
                function searchArrayByValue(arr, searchQuery) {
                  return arr
                  .map((obj) => ({
                    ...obj,
                    updated_at_temp: new Date(obj.updated_at)
                      .toLocaleDateString("en-GB") 
                      .split("/") // Convert to "dd-mm-yyyy"
                      .join("-"),
                    originalUpdatedAt: obj.updated_at, 
                  }))
                  .filter((obj) => {
                    return Object.values(obj).some((val) => {
                      if (typeof val === "string") {
                        return val
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase());
                      }
                      if (typeof val === "number") {
                        return val
                          .toString()
                          .includes(searchQuery)
                      }
                      return false;
                    });
                  })
                  .map((obj) => {
                    // Revert the `updated_at` back to its original format
                    const { originalUpdatedAt, updated_at_temp, ...rest } = obj;
                    return {
                      ...rest,
                      updated_at: originalUpdatedAt, // Restore the original format
                    };
                  });
                }
                setusers(
                  searchArrayByValue(MainUsers, e.target.value.toLowerCase())
                );
              }, 500);
            }}
          />
        </div>

        {users ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              paddingBottom: "30px",
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-row": {
                fontSize: "14px",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.navbarBG[400],
                borderBottom: "none",
                color: "#f5f5f5",
              },

              "& .MuiDataGrid-columnHeader:last-of-type": {
                borderRight: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
                borderBottom: "#000",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.navbarBG[400],
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-root": {
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-selectIcon": {
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-actions botton": {
                color: "#f5f5f5 !important",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            {" "}
            <DataGrid
              sx={{ fontSize: "13px" }}
              columns={column}
              rows={users}
              components={{ Toolbar: CustomToolbar }}
              localeText={{
                noRowsLabel: "No records found",
              }}
              rowsPerPageOptions={[10, 20, 25, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setpageSize(newPageSize)}
              getRowId={(row) => row.id} // Ensure the unique ID for rows
            />
          </Box>
        ) : (
          <Stack spacing={1}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            {/* For other variants, adjust the size with `width` and `height` */}

            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
          </Stack>
        )}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <DateRangePicker
            onChange={(item) => {
              setisDateRange(true);
              setstartDate(
                moment(item.selection.startDate).format("YYYY-MM-DD")
              );
              setendDate(moment(item.selection.endDate).format("YYYY-MM-DD"));
              setdateRange([item.selection]);
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={dateRange}
            direction="vertical"
            scroll={{ enabled: true }}
          />
          <Box mt={5}>
            {" "}
            <Button
              fullWidth
              variant="contained"
              sx={{ height: "30px", fontWeight: "700", color: "fff" }}
              color="primary"
              onClick={() => {
                if (!startDate) {
                  setisDateRange(true);
                  setstartDate(
                    moment(dateRange[0].startDate).format("YYYY-MM-DD")
                  );
                  setendDate(moment(dateRange[0].endDate).format("YYYY-MM-DD"));
                }
                handleClose();
              }}
            >
              Set
            </Button>
          </Box>
        </Box>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={backdropOpen}
        onClick={handleBackDropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default CustomersReport;
