import React, { useState } from "react";
import {
  Sidebar as ProSidebar,
  Menu,
  MenuItem,
  useProSidebar,
  sidebarClasses,
} from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { tokens } from "../theme";
import CategoryIcon from "@mui/icons-material/Category";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import GroupIcon from "@mui/icons-material/Group";
import AssessmentIcon from '@mui/icons-material/Assessment'; 
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { IconButton, Typography, useMediaQuery } from "@mui/material";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import PaymentIcon from "@mui/icons-material/Payment";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import InfoIcon from "@mui/icons-material/Info";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import RateReviewIcon from "@mui/icons-material/RateReview";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import NextWeekIcon from "@mui/icons-material/NextWeek";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import "../Styles/sidebar.css";
import { AccountBalanceWallet, ArrowDropDown, ArrowDropUp, DeliveryDiningRounded, SubscriptionsRounded } from "@mui/icons-material";
import DescriptionIcon from "@mui/icons-material/Description";
import { useSelector } from "react-redux";
import logo from "../assets/logo.png";
import whitelogo from "../assets/whitelogo.png";
import "../Styles/sidebar.css";

const Links = ({ title, to, icon, location, collapsed, active }) => {
  const theme = useTheme(); // Access the theme object
  const colors = tokens(theme.palette.mode);
  return (
    <Link to={to} style={{ all: "unset" }}>
      <MenuItem
        style={{ paddingLeft: collapsed ? "20%" : "" }}
        active={to === location}

      // icon={icon}
      >
        <span
          className="shape1"
          style={{
            backgroundColor: active
              ? colors.selectedText[100]
              : colors.selected[100],
          }}
        ></span>
        <span
          className="shape2"
          style={{
            backgroundColor: active
              ? colors.selectedText[100]
              : colors.selected[100],
          }}
        ></span>

        <span className="ps-menu-icon">{icon}</span>
        <Typography
          variant="h5"
          style={collapsed ? { display: "none" } : { display: "" }}
        >
          {title}
        </Typography>
      </MenuItem>
    </Link>
  );
};

function Sidebar() {
  const Uselocation = useLocation();
  const appSetting = useSelector((state) => {
    return state.AppSettings[state.AppSettings.length - 1];
  });
  const hasDelivery =
    appSetting &&
    appSetting?.find((setting) => setting.title == "HasDeliveryPartner")
      ?.value === "true";
  const location = Uselocation.pathname.replace("/", "");
  const matches = useMediaQuery("(max-width:767px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { collapseSidebar, collapsed } = useProSidebar();
  const admin = JSON.parse(sessionStorage.getItem("admin"));

  const [openMenus, setOpenMenus] = useState({}); // State to manage open submenus

  // Toggle submenu function
  const toggleSubmenu = (menuName) => {
    setOpenMenus((prev) => ({
      ...prev,
      [menuName]: !prev[menuName],
    }));
  };

  const links = [
    // {
    //   name: "Delivery Report",
    //   to: "DeliveryReport",
    //   display: true,
    //   icon: function () {
    //     return <QueryStatsIcon />;
    //   },
    // },

    {
      name: "Upcoming Orders",
      to: "upcoming-orders",
      display: true,
      icon: function () {
        return <NextWeekIcon />;
      },
    },
    {
      name: "Upcoming Subs",
      to: "upcoming-subs-orders",
      display: true,
      icon: function () {
        return <ContentPasteGoIcon />;
      },
    },
    {
      name: "Cart Orders",
      to: "cart-orders",
      display: true,
      icon: function () {
        return <AddShoppingCartIcon />;
      },
    },
    {
      name: "Categories",
      to: "Categories",
      display: true,
      icon: function () {
        return <CategoryIcon />;
      },
    },
    {
      name: "Subcategories",
      to: "Subcategory",
      display: true,
      icon: function () {
        return <SubtitlesIcon />;
      },
    },
    {
      name: "Products",
      to: "Products",
      display: true,
      icon: function () {
        return <ArtTrackIcon />;
      },
    },
    {
      name: "Reports",
      to: "#",
      display: true,
      icon: <QueryStatsIcon />,
      subLinks: [
        { name: "Delivery Report", to: "DeliveryReport", icon: <DeliveryDiningRounded /> },
        { name: "Customers Report", to: "CustomersReport", icon: <GroupIcon /> },
        { name: "Reconciliation Report", to: "ReconciliationReport", icon: <AssessmentIcon /> },
        { name: "Sales Report", to: "SalesReport", icon: <LocalMallIcon /> },
        { name: "Subscribers & Subscriptions", to: "SubscribersAndSubscriptions", icon: <SubscriptionsRounded /> },
      ],
    },
    {
      name: "Users",
      to: "Users",
      display: true,
      icon: function () {
        return <GroupIcon />;
      },
    },
    {
      name: "Drivers",
      to: "Drivers",
      display: hasDelivery,
      icon: function () {
        return <DirectionsBikeIcon />;
      },
    },
    {
      name: "Orders",
      to: "Orders",
      display: true,
      icon: function () {
        return <LocalMallIcon />;
      },
    },
    {
      name: "Transactions",
      to: "Transaction",
      display: true,
      icon: function () {
        return <PaymentIcon />;
      },
    },
    {
      name: "Banners",
      to: "Banners",
      display: true,
      icon: function () {
        return <ViewCarouselIcon />;
      },
    },
    {
      name: "Pincode",
      to: "Pincode",
      display: true,
      icon: function () {
        return <AddLocationAltIcon />;
      },
    },
    {
      name: "Testimonial",
      to: "Testimonial",
      display: true,
      icon: function () {
        return <RateReviewIcon />;
      },
    },
    {
      name: "Settings",
      to: "Setting",
      display: true,
      icon: function () {
        return <SettingsIcon />;
      },
    },
    {
      name: "Notifications",
      to: "Notification",
      display: true,
      icon: function () {
        return <NotificationsActiveIcon />;
      },
    },
    {
      name: "Low Wallet Notifications",
      to: "Low-Wallet-Notification",
      display: true,
      icon: function () {
        return <AccountBalanceWallet />;
      },
    },
    {
      name: "Web App Settings",
      to: "web-app-setting",
      display: true,
      icon: function () {
        return <i class="fa-brands fa-chrome"></i>;
      },
    },
    {
      name: "Invoice Settings",
      to: "invoice-setting",
      display: true,
      icon: function () {
        return <DescriptionIcon />;
      },
    },
    {
      name: "Delivery Location",
      to: "delivery-location",
      display: true,
      icon: function () {
        return <i class="fa-solid fa-street-view"></i>;
      },
    },
    {
      name: "Payment Gateway",
      to: "payment-getway",
      display: true,
      icon: function () {
        return <i class="fa-brands fa-paypal"></i>;
      },
    },
    {
      name: "Social Media",
      to: "social-media",
      display: true,
      icon: function () {
        return <i class="fa-regular fa-thumbs-up"></i>;
      },
    },

    {
      name: "Privacy",
      to: "Privacy",
      display: true,
      icon: function () {
        return <ManageAccountsIcon />;
      },
    },
    {
      name: "Terms",
      to: "Terms",
      display: true,
      icon: function () {
        return <LibraryBooksIcon />;
      },
    },
    {
      name: "About-Us",
      to: "About-Us",
      display: true,
      icon: function () {
        return <InfoIcon />;
      },
    },
  ];

  return (
    <div className="sideBar">
      <Box height={"100vh"}>
        <ProSidebar
          defaultCollapsed={matches}
          backgroundColor={colors.navbarBG[400]}
          height={"100vh"}
          paddingBottom={"50px"}
          rootStyles={{
            [`.${sidebarClasses.container}`]: {
              height: "100vh",
            },
            [`.${sidebarClasses.root}`]: {
              borderRight: "none !important",
            },
          }}
        >
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                // only apply styles on first level elements of the tree
                if (level === 0)
                  return {
                    height: "40px",
                    color: disabled ? "#f5d9ff" : "#d359ff",
                    backgroundColor: active ? "#6870fa" : undefined,
                    "&:hover": {
                      backgroundColor: "transparent !important",
                    },
                  };
              },
            }}
          >
            <MenuItem
              onClick={() => collapseSidebar()}
              style={{
                marginTop: "10px",
                color: "#fff !important",
              }}
            >
              {collapsed ? (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  color="#fff !important"
                >
                  <IconButton onClick={() => collapseSidebar()}>
                    <MenuOutlinedIcon
                      onClick={() => collapseSidebar()}
                      color="sideText"
                    />
                  </IconButton>
                </Box>
              ) : (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {/* <Typography variant="h3" color={"#FFF"}>
                    ziridaily
                  </Typography> */}
                  <IconButton onClick={() => collapseSidebar()}>
                    <MenuOutlinedIcon
                      onClick={() => collapseSidebar()}
                      color="sideText"
                    />
                  </IconButton>
                  <img
                    src={whitelogo} // Update with the correct image path
                    alt="Ziri Daily Logo" // Alt text for accessibility
                    style={{ height: "40px", width: "auto" }} // Adjust size as needed
                  />
                </Box>
              )}
            </MenuItem>
          </Menu>
          {/* Admin details */}
          {!collapsed && (
            <Box textAlign="left" pb="5px" pl="10px" borderBottom="0.5px solid #fcfcfc" className="adminDetails">
              <Box sx={{ paddingLeft: '1rem', }}>
                <Typography variant="h6" color={colors.greenAccent[500]}>
                  {admin?.email}
                </Typography>
                <Typography variant="h6" color={colors.greenAccent[500]}>
                  {admin?.role[0].role_title}
                </Typography>
              </Box>
            </Box>
          )}
          <Menu
            iconShape="square"
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                // only apply styles on first level elements of the tree
                if (level === 0)
                  return {
                    height: "50px",
                    color: active
                      ? `${colors.selectedText[100]} !important`
                      : `${colors.text[100]} !important`,
                    backgroundColor: active ? colors.selected[100] : undefined,
                    "&:hover": {
                      backgroundColor: `transparent`,
                      color: `${colors.primary} !important`,
                    },
                  };
              },
            }}
          >

            <Box mt="1px" sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: collapsed ? 'center' : 'flex-start',
              paddingLeft: '1rem',
            }}>
              {links.map((link, active) =>
                link.display ? (
                  <Box key={link.name} sx={{ width: '100%' }} className="mainReportLink">
                    {link.subLinks ? (
                      <>
                        <MenuItem className="menuLinkName"
                          onClick={() => toggleSubmenu(link.name)}
                          style={{
                            color: location === link.to ? colors.selectedText[100] : colors.text[100],
                          }}
                          active={link.to === location}
                        >
                          <Box display="flex" alignItems="center" width="100%" className="linkName">
                            <span
                              className="shape1"
                              style={{
                                backgroundColor: active
                                ? colors.selected[100]
                                : colors.selectedText[100],
                              }}
                            ></span>
                            <span
                              className="shape2"
                              style={{
                                backgroundColor: active
                                  ? colors.selected[100]
                                  : colors.selectedText[100],
                              }}
                            ></span>
                            <span className="ps-menu-icon">{link.icon}</span>
                            {!collapsed && (
                              <Typography variant="h5" sx={{
                                display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: "100%", paddingRight: "10px"
                              }}>
                                {link.name} <span>{openMenus[link.name] ? <ArrowDropUp /> : <ArrowDropDown />}</span>
                              </Typography>
                            )}
                          </Box>

                        </MenuItem>
                        {/* Render Submenu if open */}
                        {openMenus[link.name] &&
                          link.subLinks.map((subLink) => (
                            <Links className="reportSubLink"
                              key={subLink.name}
                              title={subLink.name}
                              to={subLink.to}
                              icon={subLink.icon}
                              location={location}
                              collapsed={collapsed}
                              // active={subLink.to === location}
                            />
                          ))}
                      </>
                    ) : (
                      link.display && (
                        <Links
                          key={link.name}
                          title={link.name}
                          to={link.to}
                          icon={link.icon()}
                          location={location}
                          collapsed={collapsed}
                        />
                      )
                    )}
                  </Box>
                ) : null
              )}
            </Box>
          </Menu>
        </ProSidebar>
      </Box >
    </div >
  );
}

export default Sidebar;
