const Utils = {
  getDayName(dateString) {
    const date = new Date(dateString);
    const options = { weekday: "long" };
    const day = date.toLocaleDateString(undefined, options);
    switch (day) {
      case "Sunday":
        return 0;
      case "Monday":
        return 1;
      case "Tuesday":
        return 2;
      case "Wednesday":
        return 3;
      case "Thursday":
        return 4;
      case "Friday":
        return 5;
      case "Saturday":
        return 6;
      default:
        return null;
    }
  },

  // Helper function to get subscription type
  getSubscriptionType(type) {
    switch (type) {
      case 1:
        return "One Time Order";
      case 2:
        return "Weekly";
      case 3:
        return "Monthly";
      case 4:
        return "Alternative Days";
      default:
        return "N/A";
    }
  },

  getOrderType(type) {
    switch (type) {
      case 1:
        return "Prepaid";
      case 2:
        return "Postpaid";
      case 3:
        return "Paynow";
      case 4:
        return "COD";
      default:
        return "N/A";
    }
  },

  getProfitLossDisplayWithColorCode(orderId, uniqueID, reportDatas) {
    // Filter records for the given orderId
    const orderRecords = reportDatas.filter(t => t.order_number === orderId);
  
    // Sort the records by a property that ensures proper order (if required)
    // Assuming `unique_id` is sequential, replace with another field if necessary
    orderRecords.sort((a, b) => a.unique_id.localeCompare(b.unique_id));
  
    let totalRefund = 0; // Initialize the cumulative refund tracker
    let remainingOrderAmount = 0; // Initialize remaining order amount
  
    // Iterate over records and calculate balance dynamically
    for (let record of orderRecords) {
      if (record.unique_id === uniqueID) {
        // When the current record matches the uniqueID, calculate and return
        const orderAmount = parseFloat(record.order_amount);
        const refundAmount = parseFloat(record.refund_amount) || 0;
  
        if (remainingOrderAmount === 0) {
          // Use the initial order amount if it's the first record
          remainingOrderAmount = orderAmount;
        }
  
        totalRefund += refundAmount; // Update cumulative refund
        remainingOrderAmount -= refundAmount; // Deduct refund from remaining order amount
  
        const profitLoss = remainingOrderAmount;
        const color = profitLoss > 0 ? "green" : profitLoss < 0 ? "red" : "black";
  
        return {
          text:
            profitLoss > 0
              ? `+${profitLoss.toFixed(2)}`
              : profitLoss < 0
              ? profitLoss.toFixed(2)
              : "0.00",
          color,
        };
      } else {
        // For non-matching records, update the refund and remaining amount
        totalRefund += parseFloat(record.refund_amount) || 0;
  
        if (remainingOrderAmount === 0) {
          remainingOrderAmount = parseFloat(record.order_amount) || 0;
        }
  
        remainingOrderAmount -= parseFloat(record.refund_amount) || 0;
      }
    }
  
    return { text: "Record Not Found", color: "black" };
  },

  // Utility function for calculating the final order amount with refund as negative
  calculateOrderAmount(orderAmountValue, refundAmountValue) {
    const orderAmount = parseFloat(orderAmountValue);
    const refundAmount = refundAmountValue ? parseFloat(refundAmountValue) : 0;

    if (isNaN(orderAmount)) {
      return null; // Return null to signify an invalid order amount
    }

    let total = refundAmount === 0 ? orderAmount : -Math.abs(refundAmount);
    return total.toFixed(2);
  },

  getProfitLossDisplay(orderId, uniqueID, reportDatas) {
    // Filter records for the given orderId
    const orderRecords = reportDatas.filter(t => t.order_number === orderId);

    // Sort the records by a property that ensures proper order (if required)
    // Assuming `unique_id` is sequential, replace with another field if necessary
    orderRecords.sort((a, b) => a.unique_id.localeCompare(b.unique_id));

    let totalRefund = 0; // Initialize the cumulative refund tracker
    let remainingOrderAmount = 0; // Initialize remaining order amount

    // Iterate over records and calculate balance dynamically
    for (let record of orderRecords) {
        if (record.unique_id === uniqueID) {
            // When the current record matches the uniqueID, calculate and return
            const orderAmount = parseFloat(record.order_amount);
            const refundAmount = parseFloat(record.refund_amount) || 0;

            if (remainingOrderAmount === 0) {
                // Use the initial order amount if it's the first record
                remainingOrderAmount = orderAmount;
            }

            totalRefund += refundAmount; // Update cumulative refund
            remainingOrderAmount -= refundAmount; // Deduct refund from remaining order amount

            const profitLoss = remainingOrderAmount;

            return profitLoss > 0
            ? `+${profitLoss.toFixed(2)}`
            : profitLoss < 0
            ? profitLoss.toFixed(2)
            : "0.00"; // Return the calculated amount as a string
        } else {
            // For non-matching records, update the refund and remaining amount
            totalRefund += parseFloat(record.refund_amount) || 0;

            if (remainingOrderAmount === 0) {
                remainingOrderAmount = parseFloat(record.order_amount) || 0;
            }

            remainingOrderAmount -= parseFloat(record.refund_amount) || 0;
        }
    }

    return "Record Not Found";
  },

  // Utility function to format the address
  formatAddress(addressFields) {
    const { flat_no, apartment_name, area, landmark, city, pincode } =
      addressFields;

    // Use an array to store address parts
    const addressParts = [
      flat_no,
      apartment_name,
      area,
      landmark,
      city,
      pincode ? `- ${pincode}` : null, // Add " - " only if pincode exists
    ];

    // Use reduce to join non-empty parts, ensuring no extra commas
    return addressParts.reduce((acc, part, index) => {
      if (!part) return acc; // Skip if part is empty

      // For the last part (pincode), ensure it starts with a space without a trailing comma
      if (index === addressParts.length - 1 && pincode) {
        return `${acc} ${part}`;
      }

      // Otherwise, add part with a comma
      return acc ? `${acc}, ${part}` : part;
    }, "");
  },

  getBase64FromImage(url, callback) {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = url;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      callback(dataURL);
    };
  },
};

export default Utils;
