import React, { useEffect, useMemo, useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Modal,
  Autocomplete,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DateRangePicker } from "react-date-range";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import moment from "moment/moment";
import { Stack } from "@mui/system";
import Skeleton from "@mui/material/Skeleton";
import { GET } from "../../Functions/apiFunction";
import api from "../../Data/api";
import "../../Styles/buttons.css";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import "jspdf-autotable";
import { addDays } from "date-fns";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Utils from "../../Global/utils";
import logo from "../../assets/a_logo.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "90vw",
    sm: "fit-content",
    md: "fit-content",
    lg: "fit-content",
    xl: "fit-content",
  },
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: 2,
  textAlign: "center",
};

function SubscriptionsReport() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [reports, setreports] = useState();
  const [mainReports, setMainReports] = useState();
  const [pageSize, setpageSize] = useState(100);

  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const [backdropOpen, setbackdropOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleBackDropOpen = () => setbackdropOpen(true);
  const handleBackDropClose = () => setbackdropOpen(false);
  const [isDateRange, setisDateRange] = useState(false);
  const [memoDate, setmemoDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [dataSet, setdataSet] = useState([]);
  const [dateRange, setdateRange] = useState([
    {
      endDate: new Date(),
      startDate: addDays(new Date(), -7),
      key: "selection",
    },
  ]);

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = `Bearer ${admin.token}`;
  const [selection, setSelection] = useState("Subscriptions Report");

  useEffect(() => {
    // Get categoriues
    const fetchData = async () => {
      let url = "";
      if (selection === "Subscriptions Report")
        url = `${api}/get_subscriptions_report`;
      else url = `${api}/get_subscriber_report`;

      const report = await GET(token, url);
      const reportData = report.data;
      setreports(reportData);
      setMainReports(reportData);
    };
    fetchData();
  }, [token, selection]);

  const column = useMemo(() => {
    let columns = [];

    if (selection === "Subscriptions Report") {
      columns = [
        { field: "order_number", headerName: "Order ID", width: 120 },
        { field: "name", headerName: "Name", width: 150 },
        { field: "s_phone", headerName: "Phone", width: 120 },
        { field: "title", headerName: "Title", width: 220 },
        {
          field: "subscription_type",
          headerName: "Subscription Type",
          width: 130,
          renderCell: (params) => {
            let subscriptionText = Utils.getSubscriptionType(
              params.row.subscription_type
            );
            return <p>{subscriptionText}</p>;
          },
        },
        {
          field: "order_type",
          headerName: "Order Type",
          width: 120,
          renderCell: (params) => {
            let orderText = Utils.getOrderType(params.row.order_type);
            return <p>{orderText}</p>;
          },
        },
        // {
        //   field: "delivered_date",
        //   headerName: "Delivery Status",
        //   width: 130,
        //   renderCell: (params) => {
        //     let deliveryText = "Not Delivered";
        //     let color = "red";
        //     if (params.row.delivered_date) {
        //       deliveryText = "Delivered";
        //       color = "green";
        //     }
        //     return (
        //       <p style={{ color }}>
        //         <b>{deliveryText}</b>
        //       </p>
        //     );
        //   },
        // },
        {
          field: "start_date",
          headerName: "Start Date",
          width: 140,
          renderCell: (params) =>
            moment.utc(params.row.start_date).local().format("DD-MM-YYYY")
        },
        { field: "pincode", headerName: "Pincode", width: 140 },
        {
          field: "qty",
          headerName: "Qty",
          width: 80,
        },
        {
          field: "order_amount",
          headerName: "Order Amount",
          width: 100,
          renderCell: (params) => (
            <p>
              {params.row.order_amount.toFixed(2)}
            </p>
          ),
        }

      ];
    } else {
      columns = [
        { field: "user_id", headerName: "Customer ID", width: 140 },
        { field: "name", headerName: "Name", width: 150 },
        { field: "email", headerName: "Email", width: 180 },
        { field: "phone", headerName: "Phone", width: 160 },
        {
          field: "total_order_amount",
          headerName: "Total Order Amount",
          width: 180,
        },
      ];
    }

    return columns;
  }, [memoDate, selection]);

  const exportToCSV = () => {
    const dateRange =
      startDate && endDate ? `Date Range: ${startDate} to ${endDate}` : null;
    let headers = [];
    let csvData = [];

    // Prepare the headers and data
    if (selection === "Subscriptions Report") {
      headers = [
        "Order ID",
        "Name",
        "Phone",
        "Title",
        "Subscription Type",
        "Order Type",
        // "Delivery Status",
        "Start Date",
        "Pincode",
        "Qty",
        "Order Amount",
      ];

      csvData = reports.map((row) => [
        row.order_number,
        row.name,
        row.s_phone,
        row.title,
        Utils.getSubscriptionType(row.subscription_type),
        Utils.getOrderType(row.order_type),
        // row.delivered_date === null ? "Not Delivered" : "Delivered",
        moment.utc(row.start_date).local().format("DD-MM-YYYY"),
        row.pincode,
        row.qty,
        row.order_amount ?? 0,
      ]);
    } else {
      headers = ["Customer ID", "Name", "Email", "Phone", "Total Order Amount"];

      csvData = reports.map((row) => [
        row.user_id,
        row.name,
        row.email,
        row.phone,
        row.total_order_amount,
      ]);
    }

    // Create worksheet and workbook
    const workbook = XLSX.utils.book_new();

    // Prepare the data for the worksheet
    const tempData = [
      ...(startDate && endDate ? [[dateRange], []] : []), // Add date range and empty row if available
      headers,
      ...csvData,
    ];

    // Convert tempData to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(tempData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users Report");

    // Set the filename and download
    XLSX.writeFile(
      workbook,
      selection === "Subscriptions Report"
        ? `Subscriptions_Report_${new Date().toLocaleDateString()}.csv`
        : `Subscribers_Report_${new Date().toLocaleDateString()}.csv`
    );
  };

  // PDF Export Function
  const exportToPDF = () => {
    const dateRange = `Date Range: ${startDate} to ${endDate}`;
    let tableColumn = [];
    let tableRows = [];

    const doc = new jsPDF({
      orientation: "landscape", // Set to landscape to increase width
      unit: "mm", // Measurement unit in millimeters
      format: "a4", // Use A3 size for wider layout
    });

    doc.setFontSize(18); // Set font size for header
    const headerText = "Subscriptions Report";
    const headerX =
      (doc.internal.pageSize.getWidth() - doc.getTextWidth(headerText)) / 2; // Center the header
    doc.text(headerText, headerX, 28); // Positioning the header text
    doc.setFontSize(12); // Reset font size for normal text

    // Add the date range below the header
    if (startDate && endDate) {
      doc.text(dateRange, 14, 40); // Positioning the date range below the header
    }

    if (selection === "Subscriptions Report") {
      Utils.getBase64FromImage(logo, (base64Logo) => {
        const logoWidth = 20;
        const logoHeight = 30;
        const pageWidth = doc.internal.pageSize.getWidth();
        doc.addImage(
          base64Logo,
          "PNG",
          pageWidth - logoWidth - 20,
          10,
          logoWidth,
          logoHeight
        );
        tableColumn = [
          { header: "Order ID", dataKey: "order_number" },
          { header: "Name", dataKey: "name" },
          { header: "Phone", dataKey: "s_phone" },
          { header: "Title", dataKey: "title" },
          { header: "Subscription Type", dataKey: "subscription_type" },
          { header: "Order Type", dataKey: "order_type" },
          // { header: "Delivery Status", dataKey: "delivered_date" },
          { header: "Start Date", dataKey: "start_date" },
          { header: "Pincode", dataKey: "pincode" },
          { header: "Qty", dataKey: "qty" },
          { header: "Order Amount", dataKey: "order_amount" },
        ];
        tableRows = reports.map((row) => [
          row.order_number,
          row.name,
          row.s_phone,
          row.title,
          Utils.getSubscriptionType(row.subscription_type),
          Utils.getOrderType(row.order_type),
          // row.delivered_date === null ? "Not Delivered" : "Delivered",
          moment.utc(row.start_date).local().format("DD-MM-YYYY"),
          row.pincode,
          row.qty,
          row.order_amount.toFixed(2) ?? "0.00",
        ]);

        const tableStartY = 10 + logoHeight + 6;

        doc.autoTable({
          head: [tableColumn.map((col) => col.header)],
          body: tableRows.map((row) => Object.values(row)),
          startY: tableStartY,
          styles: {
            fontSize: 10, // Adjust font size for table content
            cellWidth: "auto",
          },
          headStyles: {
            fillColor: [255, 165, 0], // Orange background
            textColor: [255, 255, 255], // White text
            fontSize: 10, // Reduced font size for header
            lineWidth: 0.2, // Set border thickness for header
            halign: "center", // Center-align the table headers
          },
          bodyStyles: {
            lineWidth: 0.2, // Set border thickness for body cells
            lineColor: [0, 0, 0], // Black border color
          },
          styles: {
            fontSize: 10, // Adjust font size for table content
            cellPadding: 3, // Add padding to cells for better appearance
            lineWidth: 0.2, // General border thickness
            lineColor: [0, 0, 0], // General border color
          },
          columnStyles: {
            1: { cellWidth: 20 },
            3: { cellWidth: 30 },
            10: { cellWidth: 25 },
          },
          showHead: "firstPage",
          didDrawCell: (data) => {
            if (
              data.column.dataKey === "title" &&
              data.cell.text[0].length > 15
            ) {
              doc.setFontSize(8); // Adjust font size for longer text in cells
            }
          },
        });

        // After the table is completely generated, add the page numbers
        const totalPages = doc.internal.getNumberOfPages(); // Get total pages
        doc.setFontSize(9);
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i); // Set the page context to the current page
          const pageWidth = doc.internal.pageSize.getWidth();
          const pageHeight = doc.internal.pageSize.getHeight();
          const pageText = `Page ${i} of ${totalPages}`; // Format "Page X/Y"
          const marginRight = 15;
          const marginBottom = i === 1 ? 7 : 10;

          // Add page number at the bottom-right of the page
          doc.text(
            pageText,
            pageWidth - marginRight - doc.getTextWidth(pageText),
            pageHeight - marginBottom
          );
        }

        doc.save(`Subscriptions_Report_${new Date().toLocaleDateString()}.pdf`);
      });
    } else {
      Utils.getBase64FromImage(logo, (base64Logo) => {
        const logoWidth = 20;
        const logoHeight = 30;
        const pageWidth = doc.internal.pageSize.getWidth();
        doc.addImage(
          base64Logo,
          "PNG",
          pageWidth - logoWidth - 20,
          10,
          logoWidth,
          logoHeight
        );
        tableColumn = [
          { header: "Customer ID", dataKey: "user_id", width: 30 },
          { header: "Name", dataKey: "name", width: 40 },
          { header: "Email", dataKey: "email", width: 50 },
          { header: "Phone", dataKey: "phone", width: 30 },
          {
            header: "Total Order Amount",
            dataKey: "total_order_amount",
            width: 40,
          },
        ];

        tableRows = reports.map((row) => [
          row.user_id,
          row.name,
          row.email,
          row.phone,
          row.total_order_amount,
        ]);

        const tableStartY = 10 + logoHeight + 6;

        doc.autoTable(tableColumn, tableRows, {
          startY: tableStartY,
          showHead: "firstPage",
          headStyles: {
            fillColor: [255, 165, 0], // Orange background
            textColor: [255, 255, 255], // White text
            fontSize: 10, // Reduced font size for header
            lineWidth: 0.2, // Set border thickness for header
            halign: "center", // Center-align the table headers
          },
          bodyStyles: {
            lineWidth: 0.2, // Set border thickness for body cells
            lineColor: [0, 0, 0], // Black border color
          },
          styles: {
            fontSize: 10, // Adjust font size for table content
            cellPadding: 3, // Add padding to cells for better appearance
            lineWidth: 0.2, // General border thickness
            lineColor: [0, 0, 0], // General border color
          },
        });

        // After the table is completely generated, add the page numbers
        const totalPages = doc.internal.getNumberOfPages(); // Get total pages
        doc.setFontSize(9);
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i); // Set the page context to the current page
          const pageWidth = doc.internal.pageSize.getWidth();
          const pageHeight = doc.internal.pageSize.getHeight();
          const pageText = `Page ${i} of ${totalPages}`; // Format "Page X/Y"
          const marginRight = 15;
          const marginBottom = i === 1 ? 7 : 10;

          // Add page number at the bottom-right of the page
          doc.text(
            pageText,
            pageWidth - marginRight - doc.getTextWidth(pageText),
            pageHeight - marginBottom
          );
        }
        doc.save(`Subscribers_Report_${new Date().toLocaleDateString()}.pdf`);
      });
    }
  };

  const filter = async (url) => {
    handleBackDropOpen();
    const report = await GET(token, url);
    handleBackDropClose();
    setreports(report.data);
    setMainReports(report.data);

    let array = getDateValueArray(report.data);
    const data_Set = () => {
      let arr = [];
      for (let i = 0; i < array.length; i++) {
        let data = {
          date: array[i].date,
          value: array[i].values.length,
        };
        arr.push(data);
      }
      setdataSet(arr);
    };

    data_Set();
  };

  function getDateValueArray(inputArray) {
    // Create an object to store the unique dates and their values
    const dateValueObj = inputArray.reduce((acc, curr) => {
      // Get the current date from the input array item
      const currDate = curr.date;

      // If the date is not already in the accumulator object, add it
      if (!acc[currDate]) {
        acc[currDate] = [];
      }

      // Add the current item's value to the date's array in the accumulator object
      acc[currDate].push(1);

      return acc;
    }, {});

    // Convert the accumulator object to an array
    const dateValueArray = Object.entries(dateValueObj).map(
      ([date, values]) => {
        return { date, values };
      }
    );

    return dateValueArray;
  }

  // custom toolbar
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <TextField
              InputLabelProps={{ shrink: true }}
              id="outlined-basic"
              label="Select Date Range"
              variant="outlined"
              Autocomplete={false}
              size="small"
              color="secondary"
              onKeyDown={() => {
                return false;
              }}
              onClick={handleOpen}
              value={startDate && `${startDate} - ${endDate}`}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                if (selection === "Subscriptions Report") {
                  if (isDateRange === true) {
                    let url = `${api}/get_subscriptions_report/${startDate}/${endDate}`;
                    filter(url);
                  } else {
                    let url = `${api}/get_subscriptions_report`;
                    filter(url);
                  }
                } else {
                  if (isDateRange === true) {
                    let url = `${api}/get_subscriber_report/${startDate}/${endDate}`;
                    filter(url);
                  } else {
                    let url = `${api}/get_subscriber_report`;
                    filter(url);
                  }
                }
              }}
              disabled={!startDate && !endDate}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setisDateRange(false);
                setstartDate("");
                setendDate("");
                let url =
                  selection === "Subscriptions Report"
                    ? `${api}/get_subscriptions_report`
                    : `${api}/get_subscriber_report`;
                filter(url);
                setdateRange([
                  {
                    endDate: new Date(),
                    startDate: addDays(new Date(), -7),
                    key: "selection",
                  },
                ]);
              }}
              disabled={!startDate && !endDate}
            >
              Reset
            </Button>
          </Box>

          <Box sx={{ display: "flex", gap: "15px" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={exportToCSV}
              disabled={reports.length === 0}
            >
              Export to CSV
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={exportToPDF}
              disabled={reports.length === 0}
            >
              Export to PDF
            </Button>
          </Box>
        </div>
        
      </GridToolbarContainer>
    );
  }

  return (
    <div>
      <Box sx={{ height: 600, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h2"
            component={"h2"}
            fontWeight={500}
            sx={{ textAlign: "center", pb: "8px" }}
          >
            Subscribers & Subscriptions Report
          </Typography>
          <TextField
            margin="normal"
            size="small"
            sx={{ width: { xs: "60%", sm: "300px", md: "400px" } }}
            id="Search"
            label="Search"
            name="Search"
            color="secondary"
            onChange={(e) => {
              e.preventDefault();
              setTimeout(() => {
                function searchArrayByValue(arr, searchQuery) {
                  return arr
                  .map((obj) => ({
                    ...obj,
                    start_date_temp: new Date(obj.start_date)
                      .toISOString() 
                      .split("T")[0] 
                      .split("-") 
                      .reverse() 
                      .join("-"), // Join as "DD-MM-YYYY"
                    originalStartDate: obj.start_date, 
                  }))
                  .filter((obj) => {
                    return Object.values(obj).some((val) => {
                      if (typeof val === "string") {
                        return val
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase());
                      }
                      if (typeof val === "number") {
                        return val
                          .toString()
                          .includes(searchQuery)
                      }
                      return false;
                    });
                  })
                  .map(({ originalStartDate, start_date_temp, ...rest }) => ({
                    ...rest,
                    start_date: originalStartDate, // Restore original format
                  }));
                }
                setreports(
                  searchArrayByValue(mainReports, e.target.value.toLowerCase())
                );
              }, 500);
            }}
          />
        </Box>

        <Box>
          
        <Autocomplete
          disableClearable
          options={["Subscriptions Report", "Subscribers Report"]}
          sx={{ width: "35%", marginTop: "40px", height: "25%" }}
          value={selection}
          onChange={(event, newValue) => {
            setSelection(newValue);
            setstartDate("");
            setendDate("");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Report Type"
              variant="outlined"
            />
          )}
        />
        </Box>

        {reports ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              paddingBottom: "30px",
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-row": {
                fontSize: "14px",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.navbarBG[400],
                borderBottom: "none",
                color: "#f5f5f5",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
                borderBottom: "#000",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.navbarBG[400],
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-root": {
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-selectIcon": {
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-actions botton": {
                color: "#f5f5f5 !important",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            {" "}
            <DataGrid
              sx={{ fontSize: "13px" }}
              columns={column}
              rows={reports}
              components={{ Toolbar: CustomToolbar }}
              localeText={{
                noRowsLabel: "No records found",
              }}
              rowsPerPageOptions={[10, 20, 25, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setpageSize(newPageSize)}
              getRowId={(row) => (row.id ? row.id : row.user_id)}
            />
          </Box>
        ) : (
          <Stack spacing={1}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            {/* For other variants, adjust the size with `width` and `height` */}

            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
          </Stack>
        )}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <DateRangePicker
            onChange={(item) => {
              setisDateRange(true);
              setstartDate(
                moment(item.selection.startDate).format("YYYY-MM-DD")
              );
              setendDate(moment(item.selection.endDate).format("YYYY-MM-DD"));
              setdateRange([item.selection]);
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={dateRange}
            direction="vertical"
            scroll={{ enabled: true }}
          />
          <Box mt={5}>
            {" "}
            <Button
              fullWidth
              variant="contained"
              sx={{ height: "30px", fontWeight: "700", color: "fff" }}
              color="primary"
              onClick={() => {
                if (!startDate) {
                  setisDateRange(true);
                  setstartDate(
                    moment(dateRange[0].startDate).format("YYYY-MM-DD")
                  );
                  setendDate(moment(dateRange[0].endDate).format("YYYY-MM-DD"));
                }
                handleClose();
              }}
            >
              Set
            </Button>
          </Box>
        </Box>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={backdropOpen}
        onClick={handleBackDropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default SubscriptionsReport;
