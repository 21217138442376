import React, { useContext, useState } from "react";
import { Box, IconButton, useTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { ColorModeContext } from "./../theme";
import {
  DarkModeOutlined,
  LightModeOutlined,
  LogoutOutlined,
  PersonOutlineOutlined,
} from "@mui/icons-material";

function Topbar() {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const [open, setOpen] = useState(false);

  const handleLogout = () => {
    sessionStorage.removeItem("admin");
    window.location.reload("/");
  }
  return (
    <Box display="flex" justifyContent="flex-end" p={1} width="100%">
      <Box display="flex" gap="20px">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlined />
          ) : (
            <LightModeOutlined />
          )}
        </IconButton>
        {/* <IconButton>
          <PersonOutlineOutlined />
        </IconButton> */}
        <IconButton
          onClick={() => {
            setOpen(true);
          }}
        >
          <LogoutOutlined />
        </IconButton>
        <Dialog
        open={open}
        onClose={() => setOpen(false)} 
        aria-labelledby="confirm-logout-dialog"
      >
        <DialogTitle id="confirm-logout-dialog">Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to log out? 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleLogout();
              setOpen(false); 
            }}
            color="error"
            variant="contained"
          >
            Logout
          </Button>
        </DialogActions>
      </Dialog>
      </Box>
    </Box>
  );
}

export default Topbar;
