import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import {
  Button,
  Autocomplete,
  CircularProgress,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Stack,
  Divider,
  Chip,
} from "@mui/material";
import CalculateIcon from "@mui/icons-material/Calculate";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@mui/material/styles";
import "../Styles/product.css";
import TextField from "@mui/material/TextField";
import api from "./../Data/api";
import { ADD, GET } from "../Functions/apiFunction";
import { tokens } from "../theme";
import moment from "moment";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

function NewOrder() {
  const products = useSelector((state) => {
    return state.Products[state.Products.length - 1];
  });

  const [users, setusers] = useState();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [userId, setuserId] = useState();
  const [productId, setproductId] = useState();
  const [price, setprice] = useState(0);
  const [MRP, setMRP] = useState(0);
  const [tax, settax] = useState(0);
  const [orderAmount, setorderAmount] = useState(0);
  const [date, setdate] = useState();
  const [addressID, setaddressID] = useState();
  const [quantity, setquantity] = useState(1);
  const [subsType, setsubsType] = useState("");
  const [status, setstatus] = useState();
  const [order_status, setorder_status] = useState(0);
  const [type, settype] = useState(1);
  const [paymentMode, setpaymentMode] = useState(1);

  const [name, setname] = useState();
  const [number, setnumber] = useState();
  const [apartment, setapartment] = useState();
  const [flat, setflat] = useState();
  const [area, setarea] = useState();
  const [landmark, setlandmark] = useState();
  const [city, setcity] = useState();
  const [pincode, setpincode] = useState();

  const [addnew, setaddnew] = useState(false);
  const [LOADING, setLOADING] = useState(false);
  const [address, setaddress] = useState();
  const [snakbarOpen, setsnakbarOpen] = useState(false);
  const [alertType, setalertType] = useState("");
  const [alertMsg, setalertMsg] = useState("");
  const [orderType, setOrderType] = useState(0);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [oneTimeDeliveryCharge, setOneTimeDeliveryCharge] = useState(0);
  const [subDeliveryCharge, setSubDeliveryCharge] = useState(0);
  const [freeDeliveryMax, setFreeDeliveryMax] = useState(0);
  const [deliveryAmount, setDeliveryAmount] = useState(0);
  const [calcAmt, setCalcAmt] = useState(false);
  const [startDayCode, setStartDayCode] = useState();
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [isAddressLoading, setIsAddressLoading] = useState(false);

  // days state
  const [M, setM] = useState();
  const [T, setT] = useState();
  const [W, setW] = useState();
  const [TH, setTH] = useState();
  const [F, setF] = useState();
  const [S, setS] = useState();
  const [SU, setSU] = useState();
  // dayqt
  const [M_QT, setM_QT] = useState(1);
  const [T_QT, setT_QT] = useState(1);
  const [W_QT, setW_QT] = useState(1);
  const [TH_QT, setTH_QT] = useState(1);
  const [F_QT, setF_QT] = useState(1);
  const [S_QT, setS_QT] = useState(1);
  const [SU_QT, setSU_QT] = useState(1);

  let selected_days = [
    {
      d: M,
      qt: M_QT,
      id: 1,
      name: "Monday",
      add: function () {
        setM_QT(M_QT + 1);
      },
      remove: function () {
        setM_QT(M_QT > 1 ? M_QT - 1 : 1);
      },
    },
    {
      d: T,
      qt: T_QT,
      id: 2,
      name: "Tuesday",
      add: function () {
        setT_QT(T_QT + 1);
      },
      remove: function () {
        setT_QT(T_QT > 1 ? T_QT - 1 : 1);
      },
    },
    {
      d: W,
      qt: W_QT,
      id: 3,
      name: "Wednesday",
      add: function () {
        setW_QT(W_QT + 1);
      },
      remove: function () {
        setW_QT(W_QT > 1 ? W_QT - 1 : 1);
      },
    },
    {
      d: TH,
      qt: TH_QT,
      id: 4,
      name: "Thursday",
      add: function () {
        setTH_QT(TH_QT + 1);
      },
      remove: function () {
        setTH_QT(TH_QT > 1 ? TH_QT - 1 : 1);
      },
    },
    {
      d: F,
      qt: F_QT,
      id: 5,
      name: "Friday",
      add: function () {
        setF_QT(F_QT + 1);
      },
      remove: function () {
        setF_QT(F_QT > 1 ? F_QT - 1 : 1);
      },
    },
    {
      d: S,
      qt: S_QT,
      id: 6,
      name: "Saturday",
      add: function () {
        setS_QT(S_QT + 1);
      },
      remove: function () {
        setS_QT(S_QT > 1 ? S_QT - 1 : 1);
      },
    },
    {
      d: SU,
      qt: SU_QT,
      id: 0,
      name: "Sunday",
      add: function () {
        setSU_QT(SU_QT + 1);
      },
      remove: function () {
        setSU_QT(SU_QT > 1 ? SU_QT - 1 : 1);
      },
    },
  ];

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settingIds = [16, 17, 18];
        const url = `${api}/get_web_app_settings_by_ids/${JSON.stringify(
          settingIds
        )}`;
        const result = await GET(token, url);
        if (result.response === 200) {
          const settingsMap = result.data.reduce((acc, setting) => {
            acc[setting.id] = parseFloat(setting.value);
            return acc;
          }, {});
          setOneTimeDeliveryCharge(settingsMap[16] || 0);
          setSubDeliveryCharge(settingsMap[17] || 0);
          setFreeDeliveryMax(settingsMap[18] || 0);
        } else {
          throw new Error(`Unexpected response code: ${result.response}`);
        }
      } catch (error) {
        handleSnakBarOpen();
        setalertType("error");
        setalertMsg(`Failed to fetch Delivery Details: ${error.message}`);
      }
    };
    const getUsers = async () => {
      try {
        setIsUserLoading(true);
        const url = `${api}/get_user`;
        const users = await GET(token, url);
        setusers(users?.data);
      } catch (error) {
        handleSnakBarOpen();
        setalertType("error");
        setalertMsg(`Failed to fetch User Details: ${error.message}`);
      } finally {
        setIsUserLoading(false);
      }
    };
    getUsers();
    fetchSettings();
  }, []);

  useEffect(() => {
    if (Array.isArray(products)) {
      const filtered = products?.filter((product) => {
        if (orderType === 0) {
          setDeliveryAmount(0);
          return product.subscription === 1;
        } else if (orderType === 1) {
          setDeliveryAmount(oneTimeDeliveryCharge);
          return product.subscription === 0;
        }
        return false;
      });
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
    resetOrderStates();
  }, [orderType, products]);

  const resetOrderStates = () => {
    setprice(0);
    setMRP(0);
    setorderAmount(0);
    setquantity(0);
    setsubsType("");
    settax(0);
    setSelectedProducts([]);
  };

  const selectDays = () => {
    let arr = [];
    for (let index = 0; index < selected_days.length; index++) {
      if (selected_days[index].d !== undefined) {
        arr.push({
          dayCode: selected_days[index].d,
          qty: selected_days[index].qt,
        });
      }
    }
    let string = "";

    for (let i = 0; i < arr.length; i++) {
      const obj = arr[i];
      string += `{dayCode:${obj.dayCode}, qty:${obj.qty}},`;
    }
    string = `[${string.slice(0, -1)}]`;
    return {
      arr: arr,
      string: string,
    };
  };

  var today = moment().format("YYYY-MM-DD");

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = `Bearer ${admin.token}`;

  const handleSnakBarOpen = () => setsnakbarOpen(true);
  const handleSnakBarClose = () => setsnakbarOpen(false);

  const getUserById = async (userId) => {
    try {
      const url = `${api}/get_user/${userId}`;
      const result = await GET(token, url);
      if (result.response === 200) {
        return result.data;
      } else {
        throw new Error(`Unexpected response code: ${result.response}`);
      }
    } catch (error) {
      handleSnakBarOpen();
      setalertType("error");
      setalertMsg(`Failed to fetch user with ID ${userId}:`, error);
      return;
    }
  };

  const getPincode = async (pincode) => {
    try {
      const url = `${api}/get_pincode/pincode/${pincode}`;
      const result = await GET(token, url);
      if (result.response === 200) {
        return result?.data;
      } else {
        throw new Error(`Unexpected response code: ${result.response}`);
      }
    } catch (error) {
      handleSnakBarOpen();
      setalertType("error");
      setalertMsg(`Failed to fetch pincode with  ${pincode}:`, error);
      return;
    }
  };

  const addOrder = async (e) => {
    e.preventDefault();
    setLOADING(true);
    var selectedDayCode = selectDays().arr;
    const orderTotalAmount = parseFloat(orderAmount) + deliveryAmount;
    // Check subscription type and selected days for weekly deliveries
    if (subsType === 2 && !selectDays().arr.length) {
      handleSnakBarOpen();
      setalertType("error");
      setalertMsg("Please Select Delivery Days and Per Day Quality");
      setLOADING(false);
      return;
    }

    const selectedUser = await getUserById(userId);
    if (!selectedUser) {
      handleSnakBarOpen();
      setalertType("error");
      setalertMsg("User is not active or incorrect user");
      setLOADING(false);
      return;
    }
    if (
      selectedUser &&
      selectedUser.wallet_amount < parseFloat(orderTotalAmount)
    ) {
      handleSnakBarOpen();
      setalertType("error");
      setalertMsg("Insufficiant wallet amount to place order");
      setLOADING(false);
      return;
    }
    const selectedAddressPin = address.find(
      (address) => address.id === addressID
    );
    const addressPincode = selectedAddressPin?.pincode || pincode;

    if (!addressPincode) {
      handleSnakBarOpen();
      setalertType("error");
      setalertMsg("Please update the delivery pincode to place ordr");
      setLOADING(false);
      return;
    }
    const isPincodeAvailable = await getPincode(addressPincode);
    if (!isPincodeAvailable) {
      handleSnakBarOpen();
      setalertType("info");
      setalertMsg("Sorry we are not delivering in this area");
      setLOADING(false);
      return;
    }
    // Data for the address
    const addresData = {
      user_id: userId,
      name: name,
      s_phone: number,
      flat_no: flat,
      apartment_name: apartment,
      area: area,
      landmark: landmark,
      city: city,
      pincode: pincode,
    };

    const productDetail = selectedProducts.map((item) => ({
      product_id: item.product_id.toString(),
      product_title: item.product_title,
      qty: item.qty.toString(),
      tax: item.tax.toString(),
      mrp: item.mrp.toString(),
      price: item.price.toString(),
      total_price: item.total_price.toFixed(2),
    }));

    if (addnew) {
      try {
        // First, add the address
        let url = `${api}/add_address`;
        const address = await ADD(token, url, addresData);

        if (address.response !== 200) {
          setalertType("error");
          setalertMsg(address.message || "Error adding address");
          handleSnakBarOpen();
          setLOADING(false);
          return;
        }

        // Prepare transaction data if needed (type === 3)
        let transectionID = null;
        if (type === 3) {
          const transectionData = {
            user_id: userId,
            payment_id: "",
            amount: orderTotalAmount,
            description: "Amount paid from wallet",
            type: "2",
            payment_mode: paymentMode,
          };

          url = `${api}/add_order_txn`;
          const transaction = await ADD(token, url, transectionData);

          if (transaction.response !== 200) {
            setalertType("error");
            setalertMsg("Error processing transaction");
            handleSnakBarOpen();
            setLOADING(false);
            return;
          }
          transectionID = transaction.id;
        }

        // Prepare and call the add_order API
        const orderData = {
          user_id: userId,
          product_id: orderType == 1 ? null : productId,
          order_amount: orderTotalAmount,
          start_date: date,
          qty: quantity,
          address_id: address.id,
          subscription_type: subsType,
          status: status || 0,
          order_status: order_status,
          order_type: type,
          selected_days_for_weekly: subsType === 2 ? selectDays().string : null,
          price: price,
          mrp: MRP,
          tax: tax,
          trasation_id: transectionID,
          isFromAdmin: true,
          product_detail: productDetail,
          deliveryCharge: deliveryAmount,
        };

        url = `${api}/add_order`;
        const addOrderResponse = await ADD(token, url, orderData);

        if (addOrderResponse.response === 200) {
          setalertType("success");
          setalertMsg("New Order Added successfully");
          handleSnakBarOpen();
          setTimeout(() => {
            navigate("/Orders");
          }, 1000);
        } else {
          setalertType("error");
          setalertMsg(addOrderResponse.message || "Error adding order");
          handleSnakBarOpen();
        }
      } catch (error) {
        setalertType("error");
        setalertMsg("Something went wrong! Please try again.");
        handleSnakBarOpen();
      } finally {
        setLOADING(false);
      }
    } else {
      // Non-addnew flow
      let transectionID = null;

      if (type === 3) {
        const transectionData = {
          user_id: userId,
          payment_id: "",
          amount: orderTotalAmount,
          description: "Amount paid from wallet",
          type: "2",
          payment_mode: type === 1 ? 1 : paymentMode,
        };

        const url = `${api}/add_order_txn`;
        const transaction = await ADD(token, url, transectionData);

        if (transaction.response !== 200) {
          setalertType("error");
          setalertMsg("Error processing transaction");
          handleSnakBarOpen();
          setLOADING(false);
          return;
        }
        transectionID = transaction.id;
      }

      const orderData = {
        user_id: userId,
        product_id: productId,
        order_amount: orderTotalAmount,
        start_date: date,
        qty: quantity,
        address_id: addressID,
        subscription_type: subsType,
        status: status || 0,
        order_status: order_status,
        order_type: type,
        selected_days_for_weekly: subsType === 2 ? selectDays().string : null,
        price: price,
        mrp: MRP,
        tax: tax,
        trasation_id: transectionID,
        isFromAdmin: true,
        product_detail: productDetail,
        deliveryCharge: deliveryAmount,
      };

      const url = `${api}/add_order`;
      const addOrderResponse = await ADD(token, url, orderData);

      if (addOrderResponse.response === 200) {
        setalertType("success");
        setalertMsg("New Order Added successfully");
        handleSnakBarOpen();
        setTimeout(() => {
          navigate("/Orders");
        }, 1000);
      } else {
        setalertType("error");
        setalertMsg(addOrderResponse.message || "Error adding order");
        handleSnakBarOpen();
      }
      setLOADING(false);
    }
  };

  const getAddress = async (userID) => {
    try {
      setIsAddressLoading(true);
      const url = `${api}/address/user/${userID}`;
      const add = await GET(token, url);
      if (add.response === 200) {
        setaddress(add.data);
      } else if (add.response === 201) {
        handleSnakBarOpen();
        setalertType("error");
        setalertMsg(add.message);
      } else {
        handleSnakBarOpen();
        setalertType("error");
        setalertMsg("Something went Wrong! Please Try Again");
      }
    } catch (e) {
      handleSnakBarOpen();
      setalertType("error");
      setalertMsg("Something went Wrong! Please Try Again");
    } finally {
      setIsAddressLoading(false);
    }
  };

  const getFormattedAddress = (ad) => {
    const address = [
      ad.flat_no,
      ad.apartment_name,
      ad.area,
      ad.landmark,
      ad.city,
      ad.pincode,
    ]
      ?.filter(Boolean)
      .join(" , ");
    return address;
  };

  const handleQuantityChange = (data, quantity) => {
    const quantityInt = parseInt(quantity);
    const productList = selectedProducts.map((product) => {
      if (product.product_id === data.product_id) {
        const priceWithTax = data.price * (1 + data.tax / 100);
        return {
          ...product,
          qty: quantityInt,
          total_price: priceWithTax * quantityInt,
        };
      }
      return product;
    });
    const totalQuantity = productList.reduce(
      (sum, product) => sum + product.qty,
      0
    );
    const totalPrice = productList.reduce(
      (sum, product) => sum + product.total_price,
      0
    );
    setprice(parseFloat(totalPrice).toFixed(2));
    setMRP(parseFloat(totalPrice).toFixed(2));
    setorderAmount(parseFloat(totalPrice).toFixed(2));
    setquantity(parseInt(totalQuantity));
    setSelectedProducts(productList);
  };

  const updateDeliveryQtyCharge = (qty) => {
    const taxPrice = (price * tax) / 100;
    const deliveryAmount =
      subsType == 1
        ? oneTimeDeliveryCharge
        : (taxPrice + price) * qty >= freeDeliveryMax
        ? 0
        : subDeliveryCharge;
    const orderDays = subsType === 3 ? 30 : subsType === 4 ? 90 : 1;
    if ([1, 3, 4].includes(subsType)) {
      const orderAmount =
        ((taxPrice + price) * qty + deliveryAmount) * orderDays;
      setorderAmount(parseFloat(orderAmount).toFixed(2));
      const deliveryAmt = deliveryAmount * orderDays;
      setDeliveryAmount(deliveryAmt);
    }
  };

  const handleChangeSubscription = (e) => {
    var subsType = e.target.value;
    setsubsType(subsType);
    const taxPrice = (price * tax) / 100;
    const deliveryAmount =
      subsType == 1
        ? oneTimeDeliveryCharge
        : (taxPrice + price) * quantity >= freeDeliveryMax
        ? 0
        : subDeliveryCharge;
    const orderDays = subsType === 3 ? 30 : subsType === 4 ? 90 : 1;
    if ([1, 3, 4].includes(subsType)) {
      const orderAmount =
        ((taxPrice + price) * quantity + deliveryAmount) * orderDays;
      const deliveryAmt = deliveryAmount * orderDays;
      setDeliveryAmount(deliveryAmt);
      setorderAmount(parseFloat(orderAmount).toFixed(2));
    } else {
      setorderAmount(0);
      setquantity(0);
      setDeliveryAmount(0);
      setCalcAmt(false);
    }
  };

  const calculateWeeklyAmount = () => {
    let totalQty = 0;
    let totalAmt = 0;
    let subDeliveryAmount = 0;
    let taxPrice = (price * tax) / 100;
    var selectedDayCode = selectDays().arr;
    let reorderedDayCode = selectedDayCode;
    if (!date) {
      handleSnakBarOpen();
      setalertType("error");
      setalertMsg("Select 'Start From' to calculate");
      return;
    }
    if (selectedDayCode.length === 0) {
      handleSnakBarOpen();
      setalertType("error");
      setalertMsg("Select 'Days' to calculate");
      return;
    }

    if (!selectedDayCode.some((item) => item.dayCode === startDayCode)) {
      handleSnakBarOpen();
      setalertType("error");
      setalertMsg(
        "Selected 'Start From' day not selected in the Weekly day list!"
      );
      return;
    }
    const startingIndex = selectedDayCode.findIndex(
      (element) => element.dayCode === startDayCode
    );

    if (startingIndex !== -1) {
      reorderedDayCode = [
        ...selectedDayCode.slice(startingIndex),
        ...selectedDayCode.slice(0, startingIndex),
      ];
    }
    for (let i = 0; i < 7; i++) {
      var dayQty = reorderedDayCode[i % reorderedDayCode.length];
      totalQty += dayQty["qty"] ?? 0;
      subDeliveryAmount +=
        (dayQty["qty"] ?? 0) * price >= freeDeliveryMax ? 0 : subDeliveryCharge;
    }
    totalAmt = (taxPrice + price) * totalQty + subDeliveryAmount;
    setDeliveryAmount(subDeliveryAmount);
    setquantity(totalQty);
    setorderAmount(parseFloat(totalAmt).toFixed(2));
    setCalcAmt(true);
  };

  return (
    <>
      <Snackbar
        open={snakbarOpen}
        autoHideDuration={3000}
        onClose={handleSnakBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnakBarClose}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
          borderBottom: colors.grey[300],
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => {
              navigate("/Orders");
            }}
          >
            <ArrowBackIcon />
          </IconButton>{" "}
          <h2 className="heading"> Add New Order</h2>
        </div>
      </Box>
      <Box component="form" onSubmit={addOrder}>
        <div className="product">
          <div
            className="left"
            style={{
              backgroundColor: colors.cardBG[400],
            }}
          >
            <h2>Order Details</h2>
            <p>
              Enter the required information below . You can change it anytime
              you want.
            </p>
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent={"space-between"}
              gap="20px"
              mt="20px"
            >
              {/* <Autocomplete
                disablePortal
                fullWidth
                id="combo-box-demo"
                color="secondary"
                options={users}
                onChange={(e, data) => {
                  setuserId(data.id);
                  getAddress(data.id);
                }}
                getOptionLabel={(option) =>
                  `${option?.name}   (${
                    option?.phone ? option?.phone : option?.email
                  })` || ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="User"
                    size="small"
                    fullWidth
                    required
                    color="secondary"
                  />
                )}
              /> */}
              <Autocomplete
                disablePortal
                fullWidth
                id="combo-box-demo"
                color="secondary"
                options={users}
                disabled={isUserLoading} // Pass loading state
                onChange={(e, data) => {
                  setuserId(data.id);
                  if (data?.id) getAddress(data.id);
                }}
                getOptionLabel={(option) =>
                  `${option?.name} (${
                    option?.phone ? option?.phone : option?.email
                  })` || ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="User"
                    size="small"
                    fullWidth
                    required
                    color="secondary"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isUserLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
              <Box
                display={"flex"}
                width={"50%"}
                alignItems="center"
                gap="10px"
              >
                <Typography fontSize={"16px"} fontWeight={"600"}>
                  Normal Order
                </Typography>
                <div class="toggle-switch">
                  <input
                    class="toggle-input"
                    id="toggle1"
                    type="checkbox"
                    checked={orderType === 0}
                    onChange={() => {
                      setOrderType(orderType === 1 ? 0 : 1);
                    }}
                  />
                  <label class="toggle-label-2" for="toggle1"></label>
                </div>
                <Typography fontSize={"16px"} fontWeight={"600"}>
                  Subscription Order
                </Typography>
              </Box>
            </Box>
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent={"space-between"}
              gap="20px"
              mt="20px"
            >
              {orderType === 0 ? (
                <Autocomplete
                  disablePortal
                  fullWidth
                  id="combo-box-demo"
                  color="secondary"
                  options={filteredProducts || []}
                  onChange={(e, data) => {
                    setproductId(data.id);
                    setprice(data.price);
                    setMRP(data.mrp);
                    setquantity(1);
                    setDeliveryAmount(0);
                    setsubsType("");
                    settax(data.tax);
                    setorderAmount(
                      parseFloat(
                        (data.price * data.tax) / 100 + data.price
                      ).toFixed(2)
                    );
                  }}
                  getOptionLabel={(option) =>
                    `${option?.title} (${option?.qty_text})` || ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Product"
                      size="small"
                      sx={{ fontSize: "12px" }}
                      fullWidth
                      required
                      color="secondary"
                    />
                  )}
                />
              ) : (
                <Box
                  display={"flex"}
                  width={"90%"}
                  alignItems="center"
                  gap="10px"
                >
                  <Autocomplete
                    fullWidth
                    multiple
                    options={filteredProducts || []}
                    getOptionLabel={(option) =>
                      `${option?.title} (${option?.qty_text})` || ""
                    }
                    onChange={(event, newValue) => {
                      setSelectedProducts((prevSelected) => {
                        const updatedSelection = newValue.map((item) => {
                          const existingProduct = prevSelected.find(
                            (p) => p.product_id === item.id
                          );
                          const quantity = existingProduct
                            ? existingProduct.qty
                            : 1;
                          const priceWithTax =
                            item.price * (1 + item.tax / 100);
                          const total_price = priceWithTax * quantity;
                          return {
                            product_id: item.id,
                            product_title: item.title,
                            qty: existingProduct ? existingProduct.qty : 1,
                            tax: item.tax,
                            mrp: item.mrp,
                            price: item.price,
                            total_price: existingProduct
                              ? existingProduct.total_price
                              : total_price,
                          };
                        });
                        const totalQuantity = updatedSelection.reduce(
                          (sum, product) => sum + product.qty,
                          0
                        );
                        const totalPrice = updatedSelection.reduce(
                          (sum, product) => sum + product.total_price,
                          0
                        );
                        setprice(parseFloat(totalPrice).toFixed(2));
                        setMRP(parseFloat(totalPrice).toFixed(2));
                        setorderAmount(parseFloat(totalPrice).toFixed(2));
                        setquantity(parseInt(totalQuantity));
                        return updatedSelection;
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Product"
                        required={!selectedProducts?.length}
                        size="small"
                        sx={{ fontSize: "12px" }}
                        color="secondary"
                      />
                    )}
                    renderTags={(selected) =>
                      selected.length > 0
                        ? `${selected
                            .map((item) => `${item.title.substring(0, 20)}...`)
                            .join(", ")}`
                        : ""
                    }
                  />

                  <Box mt={2}>
                    {selectedProducts.map((product) => (
                      <Box
                        key={product.product_id}
                        display="flex"
                        alignItems="center"
                        sx={{ width: "100%" }}
                        gap="10px"
                        mt="10px"
                      >
                        <Chip
                          sx={{ width: "70%" }}
                          label={
                            product.product_title.length > 20
                              ? `${product.product_title.substring(0, 20)}...`
                              : product.product_title
                          }
                        />
                        <TextField
                          label="QTY"
                          required
                          type="number"
                          variant="outlined"
                          size="small"
                          value={product.qty}
                          // onChange={(e) => {
                          //   const value = e.target.value;
                          //   if (value.length <= 3 && Number(value) <= 20) {
                          //     handleQuantityChange(product, value);
                          //   }
                          // }}
                          onChange={(e) =>
                            handleQuantityChange(product, e.target.value)
                          }
                          inputProps={{ min: 1, max: 20, maxLength: 3 }}
                          sx={{ flex: 1, width: 100 }}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>

            <Box
              display={"flex"}
              alignItems="center"
              justifyContent={"space-between"}
              gap="20px"
              mt="20px"
            >
              <TextField
                margin="normal"
                disabled
                required
                fullWidth
                id="MRP"
                label="MRP"
                name="MRP"
                type="number"
                color="secondary"
                autoComplete="number"
                size="small"
                InputProps={{ inputProps: { min: 0 } }}
                value={MRP}
              />
              <TextField
                margin="normal"
                required
                disabled
                fullWidth
                id="Price"
                label="Price"
                name="Price"
                type="number"
                color="secondary"
                autoComplete="number"
                size="small"
                InputProps={{ inputProps: { min: 0 } }}
                value={price}
              />
              <TextField
                margin="normal"
                required
                disabled
                fullWidth
                id="Tax"
                label="Tax"
                name="Tax"
                type="number"
                color="secondary"
                autoComplete="number"
                size="small"
                InputProps={{ inputProps: { min: 0 } }}
                value={tax}
              />
            </Box>
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent={"space-between"}
              gap="20px"
              mt="20px"
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="Order Amount"
                label="Order Amount"
                name="Order Amount"
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: 0 } }}
                type="number"
                color="secondary"
                size="small"
                disabled
                value={orderAmount}
              />
              <TextField
                margin="normal"
                disabled={orderType === 1 || subsType === 2}
                required
                fullWidth
                id="Quantity"
                label="Quantity"
                name="Quantity"
                type="number"
                color="secondary"
                autoComplete="number"
                size="small"
                InputProps={{ inputProps: { min: 1, max: 20 } }}
                value={quantity}
                onChange={(e) => {
                  setquantity(
                    e.target.value === "" ? "" : Math.floor(e.target.value)
                  );
                  updateDeliveryQtyCharge(
                    e.target.value === "" ? "" : Math.floor(e.target.value)
                  );
                }}
              />
              <TextField
                disabled={orderType === 1}
                margin="normal"
                required
                fullWidth
                id="Start From"
                label="Start From"
                name="Start From"
                autoComplete="number"
                type="date"
                color="secondary"
                size="small"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputProps: { min: today },
                }}
                onChange={(e) => {
                  setdate(e.target.value);
                  setStartDayCode(new Date(e.target.value).getDay());
                }}
              />
            </Box>
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent={"space-between"}
              gap="20px"
              mt="30px"
            >
              <FormControl fullWidth disabled={orderType === 1 || !productId}>
                <InputLabel
                  id="demo-simple-select-label"
                  color="secondary"
                  size="small"
                  required
                >
                  Subscription Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Subscription Type"
                  size="small"
                  fullWidth
                  required
                  color="secondary"
                  value={subsType}
                  onChange={handleChangeSubscription}
                >
                  <MenuItem value={1}>Daliy</MenuItem>
                  <MenuItem value={2}>Weekly</MenuItem>
                  <MenuItem value={3}>Monthly</MenuItem>
                  <MenuItem value={4}>Alternative days</MenuItem>
                </Select>
              </FormControl>
              <TextField
                margin="normal"
                required
                fullWidth
                id="Dalivery Charge"
                label="Dalivery Charge"
                name="Dalivery Charge"
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { min: 0 } }}
                type="number"
                color="secondary"
                size="small"
                disabled
                value={deliveryAmount}
              />
              <FormControl fullWidth>
                <InputLabel
                  id="address-select"
                  color="secondary"
                  size="small"
                  required
                >
                  Address
                </InputLabel>
                <Select
                  disabled={!userId || isAddressLoading}
                  labelId="address-select"
                  id="demo-simple-select"
                  label="Address"
                  size="small"
                  fullWidth
                  required
                  color="secondary"
                  onChange={(e) => {
                    if (e.target.value === 0) {
                      setaddressID();
                      setaddnew(true);
                    } else {
                      setaddnew(false);
                      setaddressID(e.target.value);
                    }
                  }}
                >
                  <MenuItem value={0}>Add New Address</MenuItem>
                  <br />
                  {address?.map((ad) => (
                    <MenuItem value={ad.id}>{getFormattedAddress(ad)}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent={"space-between"}
              gap="20px"
              mt="30px"
            >
              {/* Select */}
              <Box flex={1}>
                {" "}
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    color="secondary"
                    size="small"
                    required
                  >
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    size="small"
                    fullWidth
                    required
                    color="secondary"
                    onChange={(e) => {
                      setstatus(e.target.value);
                    }}
                  >
                    <MenuItem value={1}>Confirmed</MenuItem>
                    <MenuItem value={0}>Pending</MenuItem>
                    {/* <MenuItem value={2}>Canceled</MenuItem> */}
                  </Select>
                </FormControl>
              </Box>

              {orderType === 0 && (
                <>
                  {" "}
                  {/* check Boxes */}
                  <Box
                    display={"flex"}
                    alignItems="center"
                    gap="50px"
                    mt="20px"
                    flex={"1"}
                    justifyContent={orderType === 0 ? "flex-end" : "flex-start"}
                  >
                    <Box>
                      <Typography fontWeight={"600"} fontSize={"18x"}>
                        Order Status *
                      </Typography>
                      <Box display={"flex"} alignItems="center" gap="10px">
                        <Typography fontSize={"16px"} fontWeight={"600"}>
                          Pause
                        </Typography>
                        <div class="toggle-switch">
                          <input
                            class="toggle-input"
                            id="toggle1"
                            type="checkbox"
                            checked={order_status === 0}
                            onChange={() => {
                              setorder_status(order_status === 1 ? 0 : 1);
                            }}
                          />
                          <label class="toggle-label-2" for="toggle1"></label>
                        </div>
                        <Typography fontSize={"16px"} fontWeight={"600"}>
                          Active
                        </Typography>
                      </Box>
                    </Box>

                    <Divider orientation="vertical" flexItem />

                    {/* Order Type */}
                    {/* <Box>
                      <Typography fontWeight={"600"} fontSize={"18x"}>
                        Order Type *
                      </Typography>
                      <Box display={"flex"} alignItems="center" gap="10px">
                        <Typography fontSize={"16px"} fontWeight={"600"}>
                          Prepaid
                        </Typography>
                        <div class="toggle-switch">
                          <input
                            class="toggle-input"
                            id="toggle2"
                            type="checkbox"
                            checked={type === 2}
                            onChange={() => {
                              settype(type === 1 ? 2 : 1);
                            }}
                            disabled
                          />
                          <label
                            class="toggle-label-2"
                            for="toggle2"
                            disabled
                            style={{ cursor: "not-allowed" }}
                          ></label>
                        </div>
                        <Typography fontSize={"16px"} fontWeight={"600"}>
                          Postpaid
                        </Typography>
                      </Box>
                    </Box> */}
                  </Box>
                </>
              )}
              <>
                {" "}
                {/* Select */}
                <Box display={"flex"} flex={1} gap={"10px"}>
                  {" "}
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      color="secondary"
                      size="small"
                      required
                    >
                      Order Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Order Type"
                      size="small"
                      fullWidth
                      required
                      color="secondary"
                      onChange={(e) => {
                        settype(e.target.value);
                      }}
                    >
                      <MenuItem value={3}>Wallet Payment</MenuItem>
                      {/* <MenuItem value={4}>Pay Later</MenuItem> */}
                    </Select>
                  </FormControl>
                </Box>
              </>
            </Box>
            {subsType === 2 && (
              <>
                <Box
                  display={"flex"}
                  alignItems="center"
                  justifyContent={"space-between"}
                  gap="20px"
                  mt="20px"
                >
                  <Box width={"fir-content"}>
                    <Typography mt={3}>Select Delivery Days *</Typography>
                    <Stack direction="row" spacing={2} mt={1}>
                      <div
                        className={M === 1 ? "dayBTn active" : "dayBTn"}
                        onClick={() => {
                          setM(M ? null : 1);
                          setorderAmount(0);
                          setquantity(0);
                          setDeliveryAmount(0);
                          setCalcAmt(false);
                        }}
                      >
                        M
                      </div>
                      <div
                        className={T === 2 ? "dayBTn active" : "dayBTn"}
                        onClick={() => {
                          setT(T ? null : 2);
                          setorderAmount(0);
                          setquantity(0);
                          setDeliveryAmount(0);
                          setCalcAmt(false);
                        }}
                      >
                        T
                      </div>
                      <div
                        className={W === 3 ? "dayBTn active" : "dayBTn"}
                        onClick={() => {
                          setW(W ? null : 3);
                          setorderAmount(0);
                          setquantity(0);
                          setDeliveryAmount(0);
                          setCalcAmt(false);
                        }}
                      >
                        W
                      </div>
                      <div
                        className={TH === 4 ? "dayBTn active" : "dayBTn"}
                        onClick={() => {
                          setTH(TH ? null : 4);
                          setorderAmount(0);
                          setquantity(0);
                          setDeliveryAmount(0);
                          setCalcAmt(false);
                        }}
                      >
                        TH
                      </div>
                      <div
                        className={F === 5 ? "dayBTn active" : "dayBTn"}
                        onClick={() => {
                          setF(F ? null : 5);
                          setorderAmount(0);
                          setquantity(0);
                          setDeliveryAmount(0);
                          setCalcAmt(false);
                        }}
                      >
                        F
                      </div>
                      <div
                        className={S === 6 ? "dayBTn active" : "dayBTn"}
                        onClick={() => {
                          setS(S ? null : 6);
                          setorderAmount(0);
                          setquantity(0);
                          setDeliveryAmount(0);
                          setCalcAmt(false);
                        }}
                      >
                        S
                      </div>
                      <div
                        className={SU === 0 ? "dayBTn active" : "dayBTn"}
                        onClick={() => {
                          setSU(SU === 1 ? 0 : 1);
                          setorderAmount(0);
                          setquantity(0);
                          setDeliveryAmount(0);
                          setCalcAmt(false);
                        }}
                      >
                        SU
                      </div>
                    </Stack>
                    <Typography mt={3}>Set Per Day Quality *</Typography>
                    {/* <Stack direction="column" spacing={2} mt={1}>
                    {selected_days.map(
                      (s) => s.d === s.id && (
                        <div className="dayQty">
                          <p style={{ fontWeight: "600" }}>{s.name}</p>
                          <div className="qty">
                            <IconButton
                              size="small"
                              color="primary"
                              aria-label="add an alarm"
                              sx={{
                                border: "1px solid #000",
                                padding: "2px",
                              }}
                              onClick={() => {
                                s.remove();
                                setorderAmount(0);
                                setquantity(0);
                                setDeliveryAmount(0);
                                setCalcAmt(false);
                              }}
                            >
                              <RemoveIcon sx={{ fontSize: 14 }} />
                            </IconButton>
                            <b>
                              {" "}
                              <p>{s.qt}</p>
                            </b>
                            <IconButton
                              size="small"
                              color="secondary"
                              aria-label="add an alarm"
                              sx={{
                                border: "1px solid #4cceac",
                                padding: "3px",
                              }}
                              onClick={() => {
                                s.add();
                                setorderAmount(0);
                                setquantity(0);
                                setDeliveryAmount(0);
                                setCalcAmt(false);
                              }}
                            >
                              <AddIcon sx={{ fontSize: 14 }} />
                            </IconButton>
                          </div>
                        </div>
                      )
                    )}
                  </Stack> */}
                    <Stack direction="column" spacing={2} mt={1}>
                      {selected_days.map(
                        (s) =>
                          s.d === s.id && (
                            <div className="dayQty" key={s.id}>
                              <p style={{ fontWeight: "600" }}>{s.name}</p>
                              <div className="qty">
                                <IconButton
                                  size="small"
                                  color="primary"
                                  aria-label="remove quantity"
                                  sx={{
                                    border: "1px solid #000",
                                    padding: "2px",
                                  }}
                                  onClick={() => {
                                    s.remove();
                                    setorderAmount(0);
                                    setquantity(0);
                                    setDeliveryAmount(0);
                                    setCalcAmt(false);
                                  }}
                                >
                                  <RemoveIcon sx={{ fontSize: 14 }} />
                                </IconButton>
                                <b>
                                  <p>{s.qt}</p>
                                </b>
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  aria-label="add quantity"
                                  sx={{
                                    border: "1px solid #4cceac",
                                    padding: "3px",
                                  }}
                                  onClick={() => {
                                    if (s.qt < 20) {
                                      // Check if the quantity is less than 20
                                      s.add();
                                      setorderAmount(0);
                                      setquantity(0);
                                      setDeliveryAmount(0);
                                      setCalcAmt(false);
                                    }
                                  }}
                                  disabled={s.qt >= 20} // Disable button if quantity reaches 20
                                >
                                  <AddIcon sx={{ fontSize: 14 }} />
                                </IconButton>
                              </div>
                            </div>
                          )
                      )}
                    </Stack>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  alignItems="center"
                  justifyContent={"space-between"}
                  gap="20px"
                  mt="20px"
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={calculateWeeklyAmount}
                    startIcon={<CalculateIcon />}
                  >
                    Calculate
                  </Button>
                </Box>
              </>
            )}
          </div>
        </div>
        {addnew && (
          <div className="product">
            <div
              className="left"
              style={{
                backgroundColor: colors.cardBG[400],
              }}
            >
              <h2>Add New Address</h2>
              <Box
                display={"flex"}
                alignItems="center"
                justifyContent={"space-between"}
                gap="20px"
                mt="20px"
              >
                <TextField
                  margin="normal"
                  required={addnew}
                  fullWidth
                  id="Name "
                  label="Name "
                  name="Name "
                  type="text"
                  color="secondary"
                  size="small"
                  onChange={(e) => {
                    setname(e.target.value);
                  }}
                />
                <TextField
                  margin="normal"
                  required={addnew}
                  fullWidth
                  id="Phone Number"
                  label="Phone Number"
                  name="Phone Number"
                  color="secondary"
                  size="small"
                  type="tel" // sets the field to accept phone number-like input
                  onChange={(e) => {
                    // Allow only numbers and limit to 10 characters
                    const numericValue = e.target.value.replace(/\D/g, ""); // remove non-numeric characters
                    setnumber(numericValue.slice(0, 10)); // limit to 10 characters
                  }}
                  inputProps={{
                    maxLength: 10, // limit the input length
                    inputMode: "numeric", // ensures numeric keyboard on mobile
                    pattern: "[0-9]*", // restrict to numbers only
                  }}
                />
              </Box>
              <Box
                display={"flex"}
                alignItems="center"
                justifyContent={"space-between"}
                gap="20px"
                mt="20px"
              >
                <TextField
                  margin="normal"
                  required={addnew}
                  fullWidth
                  id="Flat "
                  label="Flat "
                  name="Flat "
                  type="text"
                  color="secondary"
                  size="small"
                  onChange={(e) => {
                    setflat(e.target.value);
                  }}
                />
                <TextField
                  margin="normal"
                  required={addnew}
                  fullWidth
                  id="Apartment_name"
                  label="Apartment name"
                  name="Apartment_name"
                  color="secondary"
                  size="small"
                  onChange={(e) => {
                    setapartment(e.target.value);
                  }}
                />
                <TextField
                  margin="normal"
                  required={addnew}
                  fullWidth
                  id="Area"
                  label="Area"
                  name="Area"
                  color="secondary"
                  size="small"
                  onChange={(e) => {
                    setarea(e.target.value);
                  }}
                />
              </Box>
              <Box
                display={"flex"}
                alignItems="center"
                justifyContent={"space-between"}
                gap="20px"
                mt="20px"
              >
                <TextField
                  margin="normal"
                  required={addnew}
                  fullWidth
                  id="Landmark "
                  label="Landmark "
                  name="Landmark "
                  type="text"
                  color="secondary"
                  size="small"
                  onChange={(e) => {
                    setlandmark(e.target.value);
                  }}
                />
                <TextField
                  margin="normal"
                  required={addnew}
                  fullWidth
                  id="City"
                  label="City"
                  name="City"
                  color="secondary"
                  size="small"
                  onChange={(e) => {
                    setcity(e.target.value);
                  }}
                />
                <TextField
                  margin="normal"
                  required={addnew}
                  fullWidth
                  id="Pincode"
                  label="Pincode"
                  name="Pincode"
                  color="secondary"
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxlength: "8",
                  }}
                  size="small"
                  onChange={(e) => {
                    setpincode(e.target.value);
                  }}
                />
              </Box>
            </div>
          </div>
        )}

        <div className="delete">
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="secondary"
            disabled={subsType === 2 && !calcAmt}
            sx={{ fontWeight: "600", letterSpacing: "1px" }}
          >
            {LOADING ? <CircularProgress size={20} /> : "Add New Order"}
          </Button>
        </div>
      </Box>
    </>
  );
}

export default NewOrder;
